import React, {useState, useEffect, useRef} from 'react';
import { TextField, IconButton, AdornmentIcon} from './style'
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types'
import { InputAdornment, Tooltip  } from '@material-ui/core'
import InputMask from 'react-input-mask'
import {Icon} from '@iconify/react'



const Input = ({
    type="text", 
    label,
    className = '',
    name,
    control,
    fullWidth = false, 
    customcolor="#999", 
    width = undefined,
    children,
    defaultValue,
    rules,
    disabled,
    customError,
    helperText,
    isRequired = false,
    select = false,
    style = undefined,
    inputProps = {},
    InputLabelProps = {},
    InputProps = {},
    maskOptions = undefined,
    onDisableAdornment = undefined,
    placeholder = undefined,
    onChangeCallback = ()=>{}

})=>{
  const [showPassword, setShowPassword] = useState(false)
  const {mask, maskChar, formatChars, alwaysShowMask, from: min, to: max} = maskOptions || {}
  // useEffect(()=>{
  //   console.log(mask)
  //   if (!mask && maskOptions && name){
  //     const element = document.getElementById(name);
  //     element.addEventListener('focus', (e) => {
  //       const element = document.getElementById(name);
  //       const varMask = new IMask(element, maskOptions)
  //       setMask(varMask)
  //       console.log(mask)
  //     });
  //     const varMask = new IMask(element, maskOptions)
  //     setMask(varMask)
  //   }
  // },[mask])
  return (
    <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={ defaultValue || "" }

        render={({ field: { onChange, value, ref }, fieldState: { error } }) => maskOptions 
        /* Com Máscara  */
        ? (
          <InputMask 
            mask={mask}
            formatChars={formatChars} 
            maskChar={maskChar ||''} 
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={(e)=>{
              onChangeCallback()
              if (max && parseInt(e.target.value) > max) e.target.value = max
              if (min && (max ? String(max).length <= String(e.target.value).length : String(min).length <= String(e.target.value).length )  && parseInt(e.target.value) < min) e.target.value = min
              onChange(e)
            }}
          >
              {(inputProps) => (
            <TextField 
            type={type === 'password' ? ( showPassword ? 'text' : 'password' ) : type} 
            style = {{ paddingBottom: error || customError  ? 12 : 0}}
            label={isRequired ? <>{label} <span> * </span></> : label} 
            name={name} 
            className={className}
            variant="outlined"
            inputRef={ref}
            disabled={disabled}
            fullWidth={fullWidth}
            hasdisableadornment={onDisableAdornment && disabled ? 'true' : 'false'}
            InputLabelProps = {InputLabelProps}
            customcolor={customcolor} 
            width={width}
            key={label}
            placeholder={placeholder}
            isrequired = { isRequired ? 'true' : 'false' }
            InputProps={
               { 
                // inputComponent:CustomInput,
                className: type === 'date' && !value ? 'isEmpty' : '',
                endAdornment: type === 'password' 
                ? (
                  <InputAdornment position="end">
                    <AdornmentIcon 
                        onClick={() => setShowPassword(current=> !current)}
                        width={24}
                        color="gray"
                        icon={showPassword ? "ic:baseline-visibility" : "ic:baseline-visibility-off"}
                      />
                  </InputAdornment>
                )
                : (
                  onDisableAdornment && disabled 
                  ? <InputAdornment position="end">
                    <Tooltip title={onDisableAdornment?.tooltip}>
                      <AdornmentIcon 
                        onClick={onDisableAdornment?.onClick}
                        width={onDisableAdornment?.width}
                        color={onDisableAdornment?.color}
                        icon={onDisableAdornment?.icon}
                      />
                  </Tooltip>
                </InputAdornment>
                  : undefined
                )

              }
            }
            inputProps={{id: name}}
            error={ !!customError || !!error }
            helperText={
              error?.message ? error?.message  : ( customError ? customError : helperText ||'' )
            }
            select={select}
            {...inputProps}
            >
            {children}    
            </TextField>
          )}
          </InputMask>
        )
        /* Sem Máscara  */
        :(
          <TextField 
            type={type === 'password' ? ( showPassword ? 'text' : 'password' ) : type} 
            style = {{ paddingBottom: error || customError  ? 12 : 0}}
            label={isRequired ? <>{label} <span> * </span></> : label} 
            name={name} 
            className={className}
            variant="outlined"
            inputRef={ref}
            disabled={disabled}
            InputLabelProps = {InputLabelProps}
            fullWidth={fullWidth} 
            customcolor={customcolor} 
            width={width}
            key={label}
            placeholder={placeholder}
            isrequired = { isRequired ? 'true' : 'false' }
            InputProps={
               { 
                // inputComponent:CustomInput,
                className: type === 'date' && !value ? 'isEmpty' : '',
                endAdornment: type === 'password' 
                ? (
                  <InputAdornment position="end">
                    <IconButton type="button" onClick={() => setShowPassword(current=> !current) }>
                      <Icon width={24} color="gray" icon={showPassword ? "ic:baseline-visibility" : "ic:baseline-visibility-off"}/>
                    </IconButton>
                  </InputAdornment>
                )
                : (
                  onDisableAdornment && disabled 
                  ? <InputAdornment position="end">
                    <Tooltip title={onDisableAdornment?.tooltip}>
                      <AdornmentIcon 
                        onClick={onDisableAdornment?.onClick}
                        width={onDisableAdornment?.width}
                        color={onDisableAdornment?.color}
                        icon={onDisableAdornment?.icon}
                      />
                  </Tooltip>
                </InputAdornment>
                  : undefined
                )
              }
            }
            inputProps={{id: name}}
            error={ !!customError || !!error }
            helperText={
              error?.message ? error?.message  : ( customError ? customError : helperText ||'' )
            }
            value={value}
            onChange={(e)=>{
              onChangeCallback()
              onChange(e)
            }}
            select={select}
            >
            {children}    
            </TextField>
        )}

    />
    
  )
};

Input.defaultprops = {
  type: 'text',
  fullWidth: false,
  select: false,
  disabled: false,
  children: <></>,
  defaultValue: '',
  label: '',
  placeholder: undefined
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.any.isRequired,
  placeholder: PropTypes.string
};

export default Input