/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, ButtonGroup } from "@material-ui/core";
import {
  Header,
  TypeOF,
  TypeFF,
  Table,
  TableLeft,
  TableRight,
  Typog,
  AbaIL,
  ContainerAbas,
  Tabela,
  AbaDT,
  BoxImage,
  ContainerGrafico,
  Button,
  Subtitle,
  Title,
  LottieWrapper,
} from "./style";
import Loader from "../../components/Loader";
import LogoAlesca from "../../assets/svg/logoAlesca";
import api, { apiFP } from "../../services/api";
import getImgURL from "../../utils/getImgUrl";
import semFoto from "../../assets/imgs/sem-foto.png";
import ImageGallery from "react-image-gallery";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import "react-image-gallery/styles/css/image-gallery.css";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import UnoMillie from "../../assets/imgs/carro.png";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/gerando_laudo";
import "./style.css";
import RawImageMagnify from "react-image-magnify";

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Laudo() {
  const [isLoading, setLoading] = useState(true);
  const [isGenerating, setGenerating] = useState(false);
  const [isLoadingGaleria, setLoadingGaleria] = useState(true);
  const location = useLocation();
  const [aba, setAba] = useState(true);
  const navigate = useNavigate();
  const [galeriaState, setGaleriaState] = useState(false);
  const [galeriaLoc, setGaleriaLoc] = useState(0);
  const [fotoFrente, setFotoFrente] = useState();
  const [infoLaudo, setInfoLaudo] = useState({});
  const [fotosArray, setFotosArray] = useState([]);
  const [grafico, setGrafico] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [auxDetalhes, setAuxDetalhes] = useState({});
  const veiculo = location?.state?.veiculo || {};
  const [images, setImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const handleGaleria = () => {
    setGaleriaState(!galeriaState);
  };
  const handleLoc = (e) => {
    const val = e.currentTarget.value;
    if (val === galeriaLoc) return setGaleriaLoc(0);
    setGaleriaLoc(e.currentTarget.value);
  };
  const fetchImages = async () => {
    try {
      const images = await api.post("/laudo/fotos", { vistoria: veiculo.id });
      const parsedImages = images.data.data.map((each) => {
        const { id, pasta, legenda: filename, extensao, locfoto } = each;
        const URL = getImgURL({
          id,
          pasta,
          filename,
          extensao,
          formaRedimenciona: "wh",
          medidas: "500+500",
          qualidade: "",
        });
        return { legenda: filename, caminho: URL, locfoto };
      });
      setImages(parsedImages);

      return images;
    } catch (err) {}
  };

  // images.find((foto) => foto.legenda.includes('Foto 45º do lado esquerdo (dianteira)')).caminho
  const getDetalhesVeiculo = async () => {
    await api.get(`/laudo/compracerta?placa=${veiculo.placa}`).then((res) => {
      setGrafico([
        res.data.conforme,
        res.data.nao_conforme,
        res.data.conforme_observacao,
      ]);
      setInfoLaudo(res.data.grupos);
    });
    apiFP.get(`/detalhes/${veiculo.id}`).then((response) => {
      console.log(response.data[0]);
      const {
        pat_descricao,
        vist_vplaca,
        vcor_descricao,
        vist_vmotorizacao,
        vist_vqtdeporta,
        vist_vtipodirecao,
        vist_vcambio,
        vist_vanofab,
        vist_vanomodelo,
        vist_vrenavam,
        vist_vchassi,
        vist_vnummotor,
        vist_vkm,
      } = response.data[0];
      setAuxDetalhes({
        pat_descricao: { value: pat_descricao, label: "Leiloeiro" },
        vist_vmarca: { value: veiculo?.marca, label: "Marca" },
        vist_vmodelo: { value: veiculo?.modelo, label: "Modelo" },
        vist_vversao: { value: veiculo?.versao, label: "Versão" },
        vcor_descricao: { value: vcor_descricao, label: "Cor" },
        vist_vmotorizacao: { value: vist_vmotorizacao, label: "Motor" },
        vist_vqtdeporta: { value: vist_vqtdeporta, label: "Qtd. de Portas" },
        vist_vtipodirecao: {
          value: vist_vtipodirecao,
          label: "Tipo de Direção",
        },
        vist_vcambio: { value: vist_vcambio, label: "Câmbio" },
        vist_vanofab: { value: vist_vanofab, label: "Ano Fab." },
        vist_vanomodelo: { value: vist_vanomodelo, label: "Ano Mod." },
        vist_vplaca: { value: vist_vplaca, label: "Placa" },
        vist_vrenavam: { value: vist_vrenavam, label: "Renavam" },
        vist_vchassi: { value: vist_vchassi, label: "Nº de Chassi" },
        vist_vnummotor: { value: vist_vnummotor, label: "Nº do Motor" },
        vist_vkm: { value: vist_vkm + " Km", label: "Quilometragem" },
      });
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!veiculo?.id) navigate("/meus-laudos", { replace: true });
    else {
      fetchImages();
    }
    getDetalhesVeiculo();
  }, []);
  useEffect(() => {
    images?.find((compare) => {
      compare.legenda === "Foto Dianteira"
        ? setFotoFrente(compare.caminho)
        : "";
    });
    const imgArray = images?.map((res) => ({
      original: res.caminho,
      thumbnail: res.caminho,
      description: res.legenda,
      originalWidth: 330,
      locfoto: res.locfoto,
    }));
    setFotosArray(imgArray);
    setGalleryImages(imgArray);
    setGaleriaState(false);
    setGaleriaLoc(0);
  }, [images]);

  const handleChangeDT = () => {
    setAba(!aba);
  };

  const state = {
    series: grafico,
    options: {
      dataLabels: {
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      colors: ["#29A87C", "#FF3A67", "#F8A72D"],
      chart: {
        dropShadow: {
          enabled: false,
          top: 5,
          left: 0,
          blur: 5,
          opacity: 0.5,
        },
        width: 480,
        type: "pie",
      },
      labels: ["Conforme", "Não Conforme", "Conforme Com Obs"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "center",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    setLoadingGaleria(true);
    let noImg = [
      {
        original: semFoto,
        thumbnail: semFoto,
        originalWidth: 330,
        locfoto: galeriaLoc,
      },
    ];
    //Filtro Apontamentos
    let tempImgs = !galeriaState
      ? fotosArray
      : fotosArray?.filter((img) =>
          img?.description?.toLowerCase()?.includes("avaria")
        );
    //Filtro Localização
    tempImgs =
      galeriaLoc != 0
        ? tempImgs.filter((img) => img.locfoto == galeriaLoc)
        : tempImgs;
    tempImgs = tempImgs.length > 0 ? tempImgs : noImg;
    setGalleryImages(tempImgs);
    setTimeout(() => setLoadingGaleria(false), 1000);
  }, [galeriaState, galeriaLoc]);

  const capitalizeEveryFirstLatter = (string) => {
    return (
      //função para transforamr todas as primeiras letras de cada palavra em maiuscula
      string
        .toLowerCase()
        .split(" ")
        .map((each) => each.charAt(0).toUpperCase() + each.slice(1))
        .join(" ")
    );
  };

  const getLaudo = async (placa) => {
    setGenerating(true);
    const res = await api.get(`/laudo/compracerta?placa=${placa}&amp&pdf=1`, {
      withCredentials: true,
      responseType: "blob",
    });
    setGenerating(false);
    const pdfUrl = window.URL.createObjectURL(res.data);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = pdfUrl;
    a.download = `${placa} Laudo Compra Certa`;
    a.click();
    window.URL.revokeObjectURL(pdfUrl);
  };

  return isLoading ? (
    <Loader visible={true} style={{ height: "calc(100% - 60px)" }} />
  ) : (
    <>
      {isGenerating && (
        <LottieWrapper>
          <Lottie options={animationOptions} height={400} width={400} />
          <h2>Gerando laudo. Por favor aguarde...</h2>
        </LottieWrapper>
      )}
      <Grid container style={{ padding: "2%" }}>
        <Grid item xs={12}>
          <Grid
            container
            style={{ height: "100%" }}
            justifyContent="space-around"
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ backgroundColor: "#F8F9FB" }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TypeOF>
                        {capitalizeEveryFirstLatter(veiculo.modelo)}
                      </TypeOF>
                      <TypeFF>
                        {capitalizeEveryFirstLatter(veiculo.marca)}
                      </TypeFF>
                    </Grid>
                    <Grid item xs={12} lg={8} style={{ minHeight: 200 }}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          style={{ position: "relative", marginTop: 10 }}
                        >
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            style={{ height: 360, padding: "0% 0% 70% 0%" }}
                          >
                            <Grid
                              container
                              spacing={1}
                              style={{ paddingTop: 0 }}
                            >
                              <Grid item xs={12} lg={6}>
                                <Button onClick={handleGaleria} fullWidth>
                                  {!galeriaState
                                    ? "Ver Apontamentos"
                                    : "Ver Todas"}
                                </Button>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <ButtonGroup
                                  variant="contained"
                                  fullWidth
                                  style={{ height: "100% !important" }}
                                >
                                  <Button
                                    value="2"
                                    isactive={2 == galeriaLoc ? "true" : ""}
                                    onClick={handleLoc}
                                  >
                                    Interno
                                  </Button>
                                  <Button
                                    value="1"
                                    isactive={1 == galeriaLoc ? "true" : ""}
                                    onClick={handleLoc}
                                  >
                                    Externo
                                  </Button>
                                  <Button
                                    value="3"
                                    isactive={3 == galeriaLoc ? "true" : ""}
                                    onClick={handleLoc}
                                  >
                                    Estrutura
                                  </Button>
                                </ButtonGroup>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    minHeight: 340,
                                    position: "relative",
                                  }}
                                >
                                  {isLoadingGaleria && (
                                    <Loader visible={isLoadingGaleria} />
                                  )}
                                  <ImageGallery
                                    showPlayButton={false}
                                    items={galleryImages}
                                    style={{
                                      width: "100%",
                                      minHeight: 340,
                                      position: "relative",
                                    }}
                                    renderItem={(props) => (
                                      <>
                                        <RawImageMagnify
                                          {...{
                                            smallImage: {
                                              isFluidWidth: true,
                                              src: props.original,
                                              width: 515,
                                              height: 290,
                                            },
                                            largeImage: {
                                              src: props.original,
                                              width: 2575,
                                              height: 1450,
                                            },
                                            enlargedImagePosition: "over",
                                            enlargedImageContainerDimensions: {
                                              width: "200%",
                                              height: "100%",
                                            },
                                            style: {
                                              marginTop: "-4%",
                                              zIndex: "10",
                                            },
                                            enlargedImageContainerClassName:
                                              "enlargedContainer",
                                            className: "rootClass",
                                          }}
                                        />
                                        <div
                                          item
                                          style={{
                                            position: "absolute",
                                            left: 0,
                                            bottom: 10,
                                            zIndex: "10",
                                            backgroundColor:
                                              "rgba(0, 0, 0, .4)",
                                            height: 40,
                                            fontSize: 30,
                                            padding: "8px 18px 8px 40px",
                                            color: "#fff",
                                            fontWeight: "500",
                                            alignItems: "center",
                                            display: "flex",
                                          }}
                                        >
                                          {props?.description?.toUpperCase() ||
                                            ""}
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  onClick={() =>
                                    navigate("/meus-laudos/fotos", {
                                      state: { veiculo },
                                    })
                                  }
                                  fullWidth
                                >
                                  <Icon
                                    icon="ic:sharp-photo-library"
                                    width={24}
                                    style={{ marginRight: 16 }}
                                    color="white"
                                  />
                                  Lista de fotos
                                </Button>
                              </Grid>
                            </Grid>

                            {/* <BoxImage>
                              <img src={images?.find(compare =>(compare.legenda === 'Foto 45º do lado esquerdo (dianteira)'))?.caminho}
                              style={{width:'100%', filter:'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7))', borderRadius:'10px;'}}/>
                            </BoxImage>
                            <BoxImage>
                            <img src={images?.find(compare =>(compare.legenda === 'Foto 45º do lado esquerdo (dianteira)'))?.caminho}
                              style={{width:'100%', filter:'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7))', borderRadius:'20px; !important'}}/>
                            </BoxImage>
                            <BoxImage>
                            <img src={images?.find(compare =>(compare.legenda === 'Foto 45º do lado esquerdo (dianteira)'))?.caminho}
                              style={{width:'100%', filter:'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7))', borderRadius:'20px; !important'}}/>
                            </BoxImage>
                            <BoxImage>
                            <img src={images?.find(compare =>(compare.legenda === 'Foto 45º do lado esquerdo (dianteira)'))?.caminho}
                              style={{width:'100%', filter:'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7))', borderRadius:'20px; !important'}}/>
                            </BoxImage> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ margin: "2% 0 0 0" }}>
                      <Grid item xs={12} md={12} lg={12}>
                        <ContainerAbas container>
                          <AbaIL
                            isactive={!aba ? "true" : ""}
                            item
                            xs={3}
                            onClick={!aba ? handleChangeDT : () => {}}
                          >
                            <span style={{ fontFamily: "Roboto" }}>
                              Detalhes
                            </span>
                          </AbaIL>
                          <AbaDT
                            item
                            xs={4}
                            isactive={aba ? "true" : ""}
                            onClick={aba ? handleChangeDT : () => {}}
                          >
                            <span style={{ fontFamily: "Roboto" }}>
                              Apontamentos
                            </span>
                          </AbaDT>
                        </ContainerAbas>
                        <Tabela container>
                          <Grid
                            item
                            xs={6}
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            {!aba &&
                              infoLaudo?.map((info, index) => (
                                <Typog key={info.descricao + index}>
                                  {capitalizeEveryFirstLatter(info?.descricao)}
                                </Typog>
                              ))}{" "}
                            {aba &&
                              Object.keys(auxDetalhes)?.map(
                                (key, index) =>
                                  auxDetalhes[key].value && (
                                    <Typog key={auxDetalhes[key] + index}>
                                      {auxDetalhes[key]?.label}
                                    </Typog>
                                  )
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            {!aba &&
                              infoLaudo?.map((info, index) => (
                                <Typog key={info?.descricao + index}>
                                  {parseInt(info.conforme_observacao) +
                                    parseInt(info.nao_conforme)}{" "}
                                  Apontamentos
                                </Typog>
                              ))}{" "}
                            {aba &&
                              Object.keys(auxDetalhes)?.map(
                                (key, index) =>
                                  auxDetalhes[key].value && (
                                    <Typog
                                      key={auxDetalhes[key] + index}
                                      className="value"
                                    >
                                      {auxDetalhes[key]?.value}
                                    </Typog>
                                  )
                              )}
                          </Grid>
                        </Tabela>
                      </Grid>
                      <Grid item xs={12} style={{ margin: "16px 0" }}>
                        <Button
                          onClick={() => getLaudo(veiculo.placa)}
                          fullWidth
                        >
                          <Icon
                            icon="line-md:document-report"
                            style={{ marginRight: 16 }}
                            width={24}
                            color="white"
                          />
                          <span>LAUDO COMPLETO</span>
                        </Button>
                      </Grid>

                      <Grid item xs={12} style={{ height: 360 }}>
                        <ContainerGrafico>
                          <div
                            style={{
                              fontFamily: "Roboto",
                              marginBottom: "20px",
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            Resumo dos itens verificados
                          </div>
                          <div
                            style={{
                              display: "flex",
                              margin: "auto",
                              justifyContent: "center",
                            }}
                          >
                            <ReactApexChart
                              options={state.options}
                              series={state.series}
                              type={"pie"}
                              width={400}
                            />
                          </div>
                        </ContainerGrafico>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Laudo;
