
const initialState = false

export default function func(state = initialState, action){
    const {type} = action
    switch(type){
        case "@sidebar/TOGGLE":
        return !state
        
        default: 
        return state
    }
    
}
