import React from 'react';
import {Icon} from '@iconify/react'
import { Grid } from '@material-ui/core'
import {useNavigate} from 'react-router-dom'
import { ConfirmContainer, ConfirmText, ConfirmedButton } from './styles'

const Confirmed = () => {
    const navigate = useNavigate()
    return(
        <div style={{width: '100%', textAlign: 'center'}}>
            <ConfirmContainer>
                <Icon icon="line-md:confirm-circle" />
                <ConfirmText>Seu pagamento foi processado com sucesso!</ConfirmText>
            </ConfirmContainer>
            <ConfirmedButton onClick={()=>navigate('meus-laudos')}>ver meus laudos</ConfirmedButton>
        </div>
    )
}
export default Confirmed;