import styled from 'styled-components'


export const Container = styled.div`
    position: absolute;
    top: 0;
    display: table;
    visibility: hidden;
    width: 100%;
    height: 0;
    background-color: #2b7de1;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    z-index: 9000;
    @media (max-width:767px){
        position: fixed;
        top: 0;
        visibility: visible;
        height: 60px;
    }
    button {
        background: none;
        border: none;
        align-items: center;
    }
    img {
        position: absolute;
        top: 9px;
        height: 40px;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 1s ease;
        opacity:1;
    }
`;

export const IconButton = styled.button`
    background-color: transparent;
    border: none;
    height: 100%;
    color: #257ce1;
    align-items: center;
    display: flex;
    cursor: pointer;
    @media (min-width: 768px) {
        display: none;
    }
`;