import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  Button,
  InfCard,
  Logo,
  SignupButton,
  SignupText,
  TextCard,
  Texto1,
  Texto2,
  Valor,
  BoxValor,
  Card,
  CardImg,
} from "./style";
import logo from "../../assets/logoo.png";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import DefaultImage from "../../assets/imgs/defaulCarImage.png";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import api, { apiFP } from "../../services/api";
import Loader from "../../components/Loader";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { login, storeLaudo, clearStoredLaudo } from "../../redux/actions/auth";
import Img from "../../assets/imgs/pegeout.jpg";
import isAuthenticated from "../../utils/auth";
import { store } from "../../redux/store";
import Swal from "sweetalert2";
import getImgURL from "../../utils/getImgUrl";
import RawButton from "@material-ui/core/Button";

const CadastroComLaudo = () => {
  const laudoRedux = store?.getState()?.auth?.laudo;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const placa = searchParams.get("placa");
  const email = searchParams.get("email");
  const firstAccess = searchParams.get("firstAccess");
  const [isFirstAccess, setFirstAccess] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [invalidUser, setInvalidUser] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [isPassMismatch, setPassMismatch] = useState(false);
  const [isPassVisible, setPassVisible] = useState(false);
  const [isAuth, setAuth] = useState(isAuthenticated());
  const [laudo, setLaudo] = useState({});
  const [userId, setUserId] = useState();
  const { handleSubmit, control, getValues, setValue } = useForm();
  const [data, setData] = useState({});
  const [userCPF, setUserCPF] = useState();
  const clearStates = () => {
    setInvalidUser(false);
    setIncorrectPassword(false);
    setPassMismatch(false);
  };
  const handleChangeUser = () => {
    setValue("senha", "");
    setValue("confirmar_senha", "");
    setPassVisible(false);
    setFirstAccess(false);
  };
  const formSubmit = async (e) => {
    clearStates();
    const { noLoadChange } = e || false;
    if (!noLoadChange) setLoading(true); // Ativar o Loader
    const { usuario, senha, confirmar_senha } = e;
    if (confirmar_senha && confirmar_senha !== senha) {
      setLoading(false);
      return setPassMismatch(true);
    }
    try {
      if (!!confirmar_senha) {
        await api.post("/usuario/cadastro", { id: userId, senha });
        // await formSubmit({usuario, senha})
      }
      const response = await api.post("/usuario/login", { usuario, senha });
      if (response.data?.data?.primeiro_acesso) {
        //Caso a api responda que é o primeiro acesso ...
        setUserCPF(response?.data?.data?.cpfcnpj);
        setUserId(response?.data?.data?.id);
        setFirstAccess(true);
        if (!noLoadChange) return setLoading(false);
        else return;
      }
      if (response?.data?.erro) throw new Error(response?.data?.codigo);
      if (response.status === 200) {
        if (!senha) {
          setPassVisible(true);
          if (!noLoadChange) return setLoading(false);
          return;
        }
        if (!noLoadChange) setLoading(false);
        toast.success(<b>Sessão iniciada.</b>, { duration: 5000 });
        const { id, nome: name, email } = response.data.data;
        dispatch(login({ id, name, email }));
        return setAuth(isAuthenticated());
      }
    } catch (err) {
      // Erros
      if (err?.response?.status === 400) {
        // Usuário inválido
        setLoading(false);
        setInvalidUser(true);
        return setFocus("usuario");
      }
      if (err?.message == 0) {
        // Senha incorreta
        setLoading(false);
        setIncorrectPassword(true);
        setValue("senha", "");
        return setFocus("senha");
      }
      setLoading(false);
      alert(
        err?.data?.mensagem ||
          err?.mensagem ||
          err?.response?.data?.mensagem ||
          err?.message ||
          err
      ); // Padrão / Desconhecido
    }
  };
  useEffect(() => {
    try {
      if (!placa)
        navigate("/login", {
          replace: true,
          state: { fromCad: true, email: email || null },
        });
      if (email) formSubmit({ usuario: email, noLoadChange: true }); // no load change === para o form náo alterar o loader quando for pegar os dados do usuario

      api
        .post("/laudo/detalhes", { placa: window.atob(placa) })
        .catch((err) => {
          Swal.fire({ icon: "error", title: "Laudo não encontrado." });
          dispatch(clearStoredLaudo());
          return navigate("/login", {
            replace: true,
            state: { fromCad: true, email: email || null },
          });
        })
        .then(async (response) => {
          if (!response?.data?.data?.erro) {
            if (laudoRedux !== placa) {
              //salva a placa no redux se a placa for diferente da armazenada atualmente
              dispatch(
                storeLaudo(
                  window.btoa(
                    JSON.stringify({ placa, id: response?.data?.data?.id })
                  )
                )
              );
            }

            const { id, pasta, legenda, extensao } =
              response.data?.data?.arquivo;

            const apiFPResponse = await apiFP.get(
              "/detalhes/" + response?.data?.data?.id
            );
            console.log(apiFPResponse.data[0]);

            const URL = getImgURL({
              id,
              pasta,
              filename: legenda,
              extensao,
              formaRedimenciona: "wh",
              medidas: "500+500",
              qualidade: "",
            });
            const imgLaudo = { legenda: legenda || "", URL: URL || "" };
            const {
              vist_vanomodelo: anoMod,
              vist_vanofab: anoFab,
              vist_vqtdeporta: portas,
            } = apiFPResponse.data[0];
            setLaudo({
              ...apiFPResponse.data[0],
              anoMod,
              anoFab,
              portas,
              ...response.data.data,
              img: imgLaudo,
              valor: 200.0,
            });
            return setLoading(false);
          } else return navigate({ pathname: "/login", replace: true });
        });
    } catch (err) {
      Swal.fire({ icon: "error", title: "Laudo não encontrado." });
      return navigate("/login", {
        replace: true,
        state: { fromCad: true, email: email || null },
      });
    }
  }, [placa]);

  useEffect(() => {
    // console.log(laudo);
  }, [laudo]);

  return isAuth ? (
    <Navigate to="/" />
  ) : (
    <form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
      <Loader visible={isLoading} />
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Logo src={logo} />
          <Texto2 style={{ marginTop: 0, paddingBottom: 30 }}>
            Compra Certa
          </Texto2>
        </Grid>
        <Grid item xs={12} md={12} lg={10}>
          <Card container>
            <Grid item xs={12} md={9} className="leftSide">
              <Grid container justifyContent="center">
                <h1 className="titleTop" id="TITLE">{`${laudo?.marca || "-"} ${
                  laudo?.modelo || ""
                } ${laudo?.versao || ""}`}</h1>
                <Grid item xs={12} md={8} lg={7}>
                  <div>
                    <CardImg
                      id="IMG"
                      {...{
                        smallImage: {
                          isFluidWidth: true,
                          src: laudo?.img?.URL || DefaultImage,
                          width: 515,
                          height: 290,
                        },
                        largeImage: {
                          src: laudo?.img?.URL || DefaultImage,
                          width: 1716,
                          height: 966,
                        },
                        enlargedImagePosition: "over",
                        style: {
                          zIndex: "10",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={5}>
                  <h1 className="titleBottom" id="TITLE">{`${
                    laudo?.marca || "-"
                  } ${laudo?.modelo || ""} ${laudo?.versao || ""}`}</h1>
                  <table
                    style={{
                      width: "98%",
                      paddingLeft: 8,
                      alignContent: "space-around",
                    }}
                  >
                    <tbody>
                      <tr>
                        <TextCard>Marca:</TextCard>
                        <InfCard>{laudo.marca}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Modelo:</TextCard>
                        <InfCard>{laudo.modelo}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Versão:</TextCard>
                        <InfCard>{laudo.versao}</InfCard>
                      </tr>
                      <tr>
                        <TextCard> {laudo.portas} Portas</TextCard>
                      </tr>
                      <tr>
                        <TextCard>Ano Modelo:</TextCard>
                        <InfCard>{laudo.anoMod || "-"}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Ano Fabricação:</TextCard>
                        <InfCard>{laudo.anoFab || "-"}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Placa:</TextCard>
                        <InfCard>{laudo.placa}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Leiloeiro:</TextCard>
                        <InfCard>{laudo.pat_descricao || "-"}</InfCard>
                      </tr>
                      <tr>
                        <TextCard>Placa:</TextCard>
                        <InfCard>{laudo.placa || "-"}</InfCard>
                      </tr>
                    </tbody>
                  </table>
                  <BoxValor>
                    {" "}
                    Valor do Laudo:{" "}
                    <span>
                      {" "}
                      R${" "}
                      {laudo?.valor
                        ? String(laudo?.valor?.toFixed(2))?.replace(".", ",")
                        : "0,00"}
                    </span>
                  </BoxValor>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} className="rightSide">
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Valor
                  style={{ margin: 0, marginBottom: 24, fontWeight: "bold" }}
                >
                  {isFirstAccess
                    ? "Defina sua senha para concluir o cadastro."
                    : "Realize o login para adquirir seu Laudo. "}
                </Valor>
                <Grid item xs={12} lg={8}>
                  <Input
                    defaultValue={email || ""}
                    name="usuario"
                    control={control}
                    disabled={isPassVisible || isFirstAccess}
                    onDisableAdornment={{
                      tooltip: "Trocar usuário",
                      icon: "ci:edit",
                      color: "#808080",
                      width: 22,
                      onClick: handleChangeUser,
                    }}
                    label="Email"
                    customcolor="#999"
                    rules={{
                      required: "Digite seu e-mail",
                      minLength: {
                        value: 6,
                        message: "Mínimo de 6 caractéres",
                      },
                    }}
                    customError={invalidUser ? "Usuário inválido" : ""}
                    fullWidth
                    type="text"
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  {isFirstAccess && (
                    <Input
                      defaultValue={userCPF}
                      name="cpfcnpj"
                      disabled
                      control={control}
                      label="CPF/CNPJ"
                      customcolor="#999"
                      maskOptions={{ mask: "999.999.999-99" }}
                      rules={{
                        required: "Escolha uma senha",
                        minLength: {
                          value: 6,
                          message: "Mínimo de 6 caractéres",
                        },
                      }}
                      customError={incorrectPassword ? "Senha incorreta" : ""}
                      fullWidth
                      type="tel"
                      style={{ marginBottom: 12 }}
                    />
                  )}
                  {(isPassVisible || isFirstAccess) && (
                    <Input
                      defaultValue={""}
                      name="senha"
                      control={control}
                      label="Senha"
                      customcolor="#999"
                      rules={{
                        required: "Escolha uma senha",
                        minLength: {
                          value: 6,
                          message: "Mínimo de 6 caractéres",
                        },
                      }}
                      customError={incorrectPassword ? "Senha incorreta" : ""}
                      fullWidth
                      type="password"
                      style={{ marginBottom: 12 }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={8}>
                  {/* Confirmar Senha */}
                  {isFirstAccess && (
                    <Input
                      defaultValue={""}
                      name="confirmar_senha"
                      control={control}
                      label="Confirmar senha"
                      customcolor="#999"
                      rules={{
                        required: "Confirme sua senha",
                        minLength: {
                          value: 6,
                          message: "Mínimo de 6 caractéres",
                        },
                      }}
                      customError={
                        isPassMismatch ? "As senhas não correspondem" : ""
                      }
                      fullWidth
                      onChangeCallback={() => setPassMismatch(false)}
                      type="password"
                      style={{ marginBottom: 12 }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={8}>
                  {/* { isPassVisible || isFirstAccess 
                    ? <SignupButton onClick={handleChangeUser} style={{marginTop: '-14px !important', marginBottom: 14}}>Usar outra conta</SignupButton>
                    : undefined
                    } */}
                  <Button type="submit" style={{ marginBottom: 12 }}>
                    Entrar
                  </Button>
                  <SignupButton onClick={() => navigate("/register")}>
                    Cadastre-se
                  </SignupButton>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default CadastroComLaudo;
