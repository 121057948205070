import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import {
  Grid,
  Fade,
  Button,
  Input,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import Pix from "../../assets/svg/pix.js";
import CCard from "../../assets/svg/ccard.js";
import Loader from "../../components/Loader";
import {
  Container,
  Title,
  Legend,
  Card,
  TextField as RawTextField,
  MenuItem,
  PayButton,
  PaymentOptions,
  QRCode,
  PixText,
} from "./styles";
import { store } from "../../redux/store";
import api, { apiFP } from "../../services/api";
import Swal from "sweetalert2";
import getImgURL from "../../utils/getImgUrl";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { clearStoredLaudo, logout } from "../../redux/actions/auth";
import { useSelector } from "react-redux";
import testCard from "../../utils/testCards";
import moment from "moment";
import DefaultImage from "../../assets/imgs/defaulCarImage.png";

const TextField = (props) => (
  <RawTextField
    variant="outlined"
    fullWidth={true}
    InputLabelProps={{
      shrink: true,
      style: { pointerEvents: "auto" },
    }}
    {...props}
  />
);

const Payment = ({ loading, setLoading }) => {
  const [paymentMethod, setPaymentMethod] = useState();
  const navigate = useNavigate();
  const thisYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const email = useSelector((store) => store.auth.email);
  let placa;
  const [laudo, setLaudo] = useState({});
  const [user, setUser] = useState({});
  const [isOpen, setOpen] = useState(false);
  const { control, handleSubmit, register, formState, watch, setValue } =
    useForm();
  const [pixData, setPixData] = useState();
  try {
    placa = store.getState()?.auth?.laudo
      ? window.atob(
          JSON.parse(window.atob(store.getState()?.auth?.laudo)).placa
        )
      : null;
  } catch (err) {
    dispatch(clearStoredLaudo());
    return navigate("/meus-laudos");
  }

  const handlePayment = async (e) => {
    setLoading(true);
    if (paymentMethod === "card") {
      const {
        cardNumber: numero,
        cardName: nome,
        expirationMonth: mes,
        expirationYear: ano,
        cardCVV: cvv,
        paymentInstallment: parcelas,
        cpf,
        dataNascimento,
      } = e;
      const formattedMonth = mes.length === 1 ? "0" + mes : mes;
      const formattedNumber = numero.replace(/[^0-9]+/g, "");
      const bandeira = testCard(formattedNumber);
      console.log("bandeira", bandeira);
      const dataDeVencimento = "01/" + formattedMonth + "/20" + ano;
      const portadorTitular = window.btoa(
        JSON.stringify({
          nome,
          dataNascimento: moment(dataNascimento).format("DD/MM/YYYY"),
          cpf: cpf.replace(/[^0-9]+/g, ""),
        })
      );
      const cartao = window.btoa(
        JSON.stringify({
          bandeira,
          cvv,
          dataDeVencimento,
          numero: formattedNumber,
        })
      );
      if (!bandeira) {
        setLoading(false);
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "A bandeira do cartão não é aceita ou o número do cartão está incorreto.",
        });
      }

      const cardPayment = await apiFP
        .post("/transaction/new", {
          userId: user.id,
          patId: user.patio,
          vistoriaId: laudo.id,
          total: laudo.valor,
          parcelas,
          paymentMethod,
          cartao,
          portadorTitular,
        })
        .catch((err) => {
          console.log(err?.response?.status);
          if (err?.response?.status === 409) dispatch(clearStoredLaudo());
          setLoading(false);
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              err?.response?.data?.mensagem ||
              err?.response?.data ||
              "Erro desconhecido.",
          });
        });
      if (cardPayment.status === 200) {
        setLoading(false);
        Swal.fire({ icon: "success", title: "Laudo adquirido com sucesso!" });
        dispatch(clearStoredLaudo());
        navigate("/meus-laudos");
      }
    } else {
      console.log(user);
      const pixPayment = await apiFP.post("/transaction/new", {
        userId: user.id,
        patio: user.patio,
        vistoriaId: laudo.id,
        total: laudo.valor,
        paymentMethod,
      });
      setPixData(pixPayment.data);
    }
  };

  const fetchLaudos = async () => {
    //end input masks
    setLoading(true);
    try {
      const decodedEmail = window.atob(email);
      const response = await api.post("/usuario/login", {
        usuario: decodedEmail,
      });
      const response2 = await api.post("/laudo/detalhes", { placa });
      const response3 = await apiFP.get("/detalhes/" + response2.data.data.id);
      const { id: userId } = response.data.data;
      setUser({ ...response.data.data, id: userId });
      const { id, pasta, legenda, extensao } = response2?.data?.data?.arquivo;
      const URL =
        id && pasta && extensao
          ? getImgURL({
              id,
              pasta,
              filename: legenda,
              extensao,
              formaRedimenciona: "wh",
              medidas: "500+500",
              qualidade: "",
            })
          : DefaultImage;
      const imgLaudo = { legenda, URL };
      const {
        vist_vanomodelo: anoMod,
        vist_vanofab: anoFab,
        vist_vqtdeporta: portas,
      } = response3.data[0];
      setLaudo({
        ...response2.data.data,
        img: imgLaudo,
        valor: 1.0,
        portas,
        anoFab,
        anoMod,
      });
      return setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      Swal.fire({ icon: "info", title: "Laudo não encontrado." });
      dispatch(clearStoredLaudo());
      return navigate("/meus-laudos");
    }
  };

  useEffect(() => {
    fetchLaudos();
  }, []);
  return (
    <form onSubmit={handleSubmit(handlePayment)}>
      <Grid container justifyContent="space-between">
        {/* Primeira Coluna - Apenas o Card */}
        <Grid item xs={12} md={8} lg={5}>
          <Card customid="payment-first">
            <h1 className="card-title">Sobre o Veículo</h1>
            {/* --- Dados do Veículo --- */}
            <img src={laudo?.img?.URL} />
            <Grid container>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Modelo: </b>
                  {laudo.modelo}
                </h2>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Versão: </b>
                  {laudo.versao}
                </h2>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Marca: </b>
                  {laudo.marca}
                </h2>
              </Grid>
              {laudo?.portas && (
                <Grid item xs={12} md={12} lg={6}>
                  <h2>
                    <b>{laudo.portas} Portas</b>
                  </h2>
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Ano Fabricação: </b>
                  {laudo.anoFab}
                </h2>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Ano Modelo: </b>
                  {laudo.anoMod}
                </h2>
              </Grid>
              <Grid item xs={12}>
                <h2>
                  <b>Placa: </b>
                  {laudo.placa}
                </h2>
              </Grid>
              {/* --- --- --- */}
              {/* --- --- --- */}
            </Grid>
            <br />
            <h1 className="card-title">Sobre o Leiloeiro</h1>
            <br />
            <Grid container>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Nome do Leiloeiro</b>
                </h2>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h2>
                  <b>Localização: </b>Cidade - UF
                </h2>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* Segunda Coluna - Card e Botão */}
        <Grid item xs={12} md={8} lg={5}>
          {/* Card */}
          <Card item customid="payment-second" style={{ position: "relative" }}>
            <h1 className="card-title">Pagamento</h1>
            {!paymentMethod ? (
              <>
                <br />
                <h2>Selecione um método de pagamento:</h2>
                <PaymentOptions container>
                  {/* <Grid item xs={12} md={6} lg={6} onClick={()=>{setPaymentMethod('pix')}}>
                                        <Pix />
                                        <span>PIX</span>
                                    </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    onClick={() => {
                      setPaymentMethod("card");
                    }}
                  >
                    <CCard />
                    Cartão de Crédito
                  </Grid>
                </PaymentOptions>
              </>
            ) : (
              !pixData && (
                <Grid container style={{ marginTop: 20 }}>
                  {paymentMethod === "pix" ? (
                    <>
                      <Grid item xs={12}>
                        <Pix />
                      </Grid>
                      <Grid item xs={12}>
                        <h4
                          style={{
                            textAlign: "center",
                            color: "#555555",
                            margin: "8px 0",
                          }}
                        >
                          PIX
                        </h4>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <CCard />
                      </Grid>
                      <Grid item xs={12}>
                        <h4
                          style={{
                            textAlign: "center",
                            color: "#555555",
                            margin: "8px 0",
                          }}
                        >
                          Cartão de Crédito
                        </h4>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setPaymentMethod();
                        setValue("paymentInstallment", 1);
                      }}
                      fullWidth
                      style={{ color: "#e89803", display: "flex !important" }}
                    >
                      Trocar
                    </Button>
                  </Grid>
                </Grid>
              )
            )}
            {paymentMethod === "card" && !pixData && (
              <>
                <Grid container spacing={2} style={{ marginTop: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      label="Número do Cartão"
                      type="tel"
                      control={control}
                      name="cardNumber"
                      className="styled-input"
                      maskOptions={{ mask: "9999 9999 9999 9999" }}
                      rules={{
                        required: "Preencha corretamente",
                        minLength: {
                          value: 16,
                          message: "Mínimo de 13 dígitos",
                        },
                        maxLength: {
                          value: 22,
                          message: "Preencha corretamente",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      control={control}
                      label={
                        <span>
                          Nome do Portador <i>(como gravado no cartão)</i>
                        </span>
                      }
                      name="cardName"
                      className="styled-input"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      control={control}
                      label="Mês de Validade"
                      name="expirationMonth"
                      type="tel"
                      placeholder="Ex: 6"
                      className="styled-input"
                      maskOptions={{
                        mask: "99",
                        from: 1,
                        to: 12,
                      }}
                      rules={{
                        required: "Preencha corretamente",
                        minLength: {
                          value: 1,
                          message: "Preencha corretamente",
                        },
                        min: { value: 1, message: "Preencha corretamente" },
                        max: { value: 12, message: "Preencha corretamente" },
                        maxLength: {
                          value: 2,
                          message: "Preencha corretamente",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      control={control}
                      label="Ano de Validade"
                      type="tel"
                      name="expirationYear"
                      className="styled-input"
                      placeholder="Ex: 23"
                      maskOptions={{
                        mask: "99",
                        from: 22,
                      }}
                      rules={{
                        required: "Deve possuir 4 dígitos",
                        minLength: {
                          value: 2,
                          message: "Deve possuir 2 dígitos",
                        },
                        maxLength: {
                          value: 2,
                          message: "Deve possuir 2 dígitos",
                        },
                        validate: (val) => {
                          if (parseInt("20" + val) < thisYear)
                            return "Cartão expirado.";
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      control={control}
                      type="tel"
                      InputLabelProps={{ style: { pointerEvents: "auto" } }}
                      maskOptions={{
                        mask: "9999",
                      }}
                      label={
                        <div>
                          CVV&nbsp;
                          <Tooltip
                            title={`
                                                            Código de Segurança ou Código de Verificação do Cartão.\n Na maioria dos casos, o número
                                                            do CVV fica atrás do seu cartão de crédito e é composto por três ou quatro dígitos.
                                                        `}
                          >
                            <Icon icon="fa-solid:question-circle" />
                          </Tooltip>
                        </div>
                      }
                      name="cardCVV"
                      className="styled-input"
                      rules={{
                        required: "Campo obrigatório",
                        minLength: { value: 3, message: "Mínimo de 3 dígitos" },
                        maxLength: {
                          value: 4,
                          message: "Preencha corretamente",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      control={control}
                      type="date"
                      label={
                        <span>
                          Data de Nascimento <i>(do portador)</i>
                        </span>
                      }
                      name="dataNascimento"
                      className="styled-input"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      control={control}
                      type="cpf"
                      label={
                        <span>
                          CPF<i>(do portador)</i>
                        </span>
                      }
                      name="cpf"
                      maskOptions={{ mask: "999.999.999-99" }}
                      className="styled-input"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      control={control}
                      defaultValue="1"
                      label="Parcelamento"
                      name="paymentInstallment"
                      select
                      className="styled-input"
                    >
                      <MenuItem value="1">1x Sem Juros</MenuItem>
                      <MenuItem value="2">2x Sem Juros</MenuItem>
                      <MenuItem value="3">3x Sem Juros</MenuItem>
                      <MenuItem value="4">4x Sem Juros</MenuItem>
                      <MenuItem value="5">5x Sem Juros</MenuItem>
                      <MenuItem value="6">6x Sem Juros</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </>
            )}
            {pixData && (
              <Grid container justifyContent="center">
                <PixText style={{ marginTop: 60 }}>
                  Utilize o Código QR abaixo para o pagamento via PIX:
                </PixText>
                <QRCode
                  src={
                    pixData && "data:image/png;base64," + pixData.qrcode_base64
                  }
                  onLoad={() => setLoading(false)}
                />

                <PixText style={{ marginTop: -20 }}>
                  ...Ou use o Pix Copia e Cola{" "}
                  <span>(clique no código para copiar)</span>
                </PixText>
                <Grid item xs={12} md={10}>
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(pixData?.code);
                      setOpen(true);
                      setTimeout(() => {
                        setOpen(false);
                      }, 2000);
                    }}
                  >
                    <Tooltip
                      className="pix-cec-tt"
                      disableHoverListener
                      open={isOpen}
                      placement="top"
                      title="Copiado para a área de transferência!"
                    >
                      <Input
                        id="copy-to-clipboard"
                        disabled
                        value={pixData?.code}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <Icon
                              icon="akar-icons:copy"
                              width="20"
                              color="#555555"
                            />
                          </InputAdornment>
                        }
                      />
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            )}

            {paymentMethod && (
              <Grid item xs={12}>
                <br />
                <h2 style={{ textAlign: "center" }}>Você pagará:</h2>
                <h2 className="price">
                  {paymentMethod === "card" &&
                    (watch("paymentInstallment") || 1) + "x de"}
                  <span>
                    &nbsp;R$&nbsp;
                    {(
                      Math.ceil(
                        (laudo?.valor /
                          (parseInt(watch("paymentInstallment")) || 1)) *
                          100
                      ) / 100
                    )
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(".", ",")}
                  </span>
                </h2>
              </Grid>
            )}

            {/* Botao */}
            {paymentMethod && !pixData && (
              <Grid item>
                <PayButton type="submit">Pagar</PayButton>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default Payment;
