import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import Magnify from 'react-image-magnify'

export const Logo = styled.img`
  width: 20%;
  display: block;
  margin: 0px auto;
  @media (max-width: 1279px){
      width: 40%;
  }
  @media (max-width: 379px){
      width: 98%;
  }
`;

export const Texto2 = styled.h1`
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: center;
margin: 0;
padding: 0;
color: #8ED100;
`;

export const Button = styled.button`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background: #257CE1;
    border-radius: 5px;
    border:none;
    cursor: pointer;
    width: 100%;
    height: 45px;
    :hover{
    background: deepskyblue;
    }
`;

export const SignupText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    margin:0;
    padding: 0;
`;

export const SignupButton = styled.button`
    font-family: Roboto;
    font-size: 14px;
    background: none;
    border: none;
    color: #6277f3;
    width: 100%;
    margin-bottom: 4%;
    :hover{
        text-decoration: underline;
        cursor: pointer;
    }

`;

export const TextCard = styled.td`
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #4F4F4F;
    top: 0;
    vertical-align: text-top;
    @media (max-width: 1150px) {
        line-height: 26px;
    }
    @media (max-width: 959px) {
        font-size: 20px;
        line-height: 48px;
    }
`;

export const InfCard = styled.td`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(79, 79, 79, 0.8);
    line-height: 24px;
    @media (max-width: 1150px) {
        line-height: 26px;
    }
    @media (max-width: 959px) {
        font-size: 20px;
        line-height: 48px;
    }
`;

export const Valor = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 35px;
    color:#4F4F4F;
    text-align:center;
    margin-top: 2% !important;
    @media (max-width: 959px) {
        font-size: 22px;
        line-height: 28px;
    }

    span {
        font-family: Roboto;
        font-weight: bold;
        font-size: 16px;
        color: #27AE60;
        margin-left: 5px;
        text-align:center;
        @media (max-width: 959px) {
        font-size: 22px;
        line-height: 28px;
    }
    }

`;

export const BoxValor = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 35px;
    color:#4F4F4F;
    text-align:center;
    margin-top: 2% !important;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    padding: 6px 0;
    margin-top: auto;
    background-color: rgba(223, 223, 223, .3);
    @media (max-width: 959px) {
        font-size: 22px;
        line-height: 28px;
    }

    span {
        font-family: Roboto;
        font-weight: bold;
        font-size: 16px;
        color: #27AE60;
        margin-left: 5px;
        text-align:center;
        @media (max-width: 959px) {
        font-size: 22px;
        line-height: 28px;
    }
    }

`;


export const CardImg = styled(Magnify)`
margin-right: 2%;
`;


export const Card = styled(Grid)`
    background-color: '#FFFF';
    flex-direction: "column";
    min-height: 100%;
    border-radius: 5;
    position: relative;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
    @media (max-width: 959px){
        width: 97% !important;
        margin: 0 1.5% !important;
    }
    .leftSide{
        padding: 1%;
        .titleBottom, .titleTop{
            margin: 0 0 0 0;
            padding: 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 26px;
            display: flex;
            align-items: center;
            text-align: justify;
            color: #4F4F4F;
            padding-bottom: 12px;
            border-bottom: 1px solid #d0d0d0;
            width: 100%;
        }  
        .titleTop{
            display: none;
        }
        @media (max-width:959px){
            .titleBottom{
                display: none;
            }
            .titleTop{
                    display: flex;
                    margin-bottom: 8px;
                }
        }
    }
    .rightSide{
        border-left: 1px solid #d0d0d0;
        min-height: 100% !important;
        padding: 0 1%;
        @media (max-width:959px){
            border-top: 1px solid #d0d0d0;
            border-left: none;
            margin-top: 2%;
            padding: 0%;
        }
    }
`;