import React from "react";
import Header from './Header'
import Sidebar from './Sidebar'
import { Main, ContentContainer } from './styles'
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";


const noSidebarRoutes = [ '/login', '/register', '/laudo', '/cadastro', '/comprar']


const Layout = ({children}) => {
    const isOpen = useSelector((props)=>(props.sidebar))
    const location = useLocation();
    const showLayout = !noSidebarRoutes.includes(location.pathname)
    return(
        <Main>
            <Sidebar display={showLayout}/>
            <ContentContainer isopen={isOpen} display={showLayout ? 'true' : 'false'}>
                <Header display={showLayout}/>
                <div id="content">
                    {children}
                </div>
            </ContentContainer>
        </Main>
    )
};


export default Layout;