import styled from 'styled-components'
import {TextField as RawTextField} from '@material-ui/core'
import { Icon }from '@iconify/react'

export const TextField = styled(RawTextField)`
    >div{
    background-color: #fff;
    }
   input{
            background: #fff !important;
        }
    :not(.styled-input){
        height: 50px !important;
        position: relative;
        width: ${(props)=> props.width ? `${props.width}px !important` : ''};
        
        input {
            padding: 15px !important;
            padding-right:${props=> props.type === 'password' ? '0 !important' : ''};
        }
        margin-bottom: ${(props)=>(props.error ? '24px !important' : '8px !important')};
        .MuiFormLabel-root:not(.Mui-error){
            color: ${(props) => `${props.customcolor} !important;`};
        };
        input.Mui-disabled{
            padding-right: 0 !important; 
        }
        ${ (props) => props.isrequired === 'true'
            ? " .MuiFormLabel-root span{\
                    color: #D30000 !important;\
                    font-weight: bold;\
                    font-size: 20px;\
                    position: absolute;\
                    right: -2px;\
                };\
                .MuiFormLabel-root {\
                    padding-right: 12px;\
                    background-color: #fff;  \
                    position: 'relative';\
                };"
            : ''
        };
        .Mui-focused:not(.Mui-error) fieldset, :hover :not(.Mui-error) fieldset{
            border-color: ${(props) => `${props.customcolor} !important;`};
        }
        .isEmpty:not(.Mui-focused) input[type=date] {
            color: transparent !important;
        }
        .MuiOutlinedInput-adornedEnd {
            padding-right: 0 !important;
        }

    }

`;

export const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;


export const AdornmentIcon = styled(Icon)`
    margin-right: 6px;
    background-color: #fff !important;
    cursor: pointer
`;