import axios from "axios";

const api = axios.create({
  headers: {
    "x-api-version": "1.0.0",
    "Content-Type": "*/*",
    Accept: "application/json",
    "Access-Control-Allow-Credentials": false,
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Origin": "https://compracerta.alesca.com.br",
    "Access-Control-Max-Age": 86400,
    "content-type": "text/html; charset=UTF-8",
    "Content-Type": "application/json; charset=utf-8",
    Pramga: "no-cache",
    "Cache-Control": "no-store, no-cache, must-revalidate",
    "Access-Control-Allow-Headers":
      "append,delete,entries,foreach,get,has,keys,set,values,Authorization,*",
    "Access-Control-Expose-Headers":
      "access-control-allow-headers,access-control-allow-methods,access-control-max-age,content-type,x-api-version,server,date,content-type,set-cookie,expires,cache-control,pragma,access-control-allow-credentials,access-control-allow-origin,access-control-max-age,access-control-allow-methods,access-control-allow-headers",
    Authorization: process.env.REACT_APP_NATUS_API_TOKEN,
  },
  // baseURL: 'http://server.se7iserver.cloudns.cl:3334',
  // baseURL: 'http://se7i-backend.ddns.net:3334',
  baseURL: "https://api.alesca.com.br/api",
  withCredentials: true,
});

export const apiFP = axios.create({
  headers: {
    Authorization: process.env.REACT_APP_FP_API_TOKEN,
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "authorization",
    "Access-Control-Allow-Origin": "https://compracerta.alesca.com.br",
    "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE",
  },
  baseURL: "https://api-compracerta.herokuapp.com",
});

export default api;
