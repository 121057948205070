export const login = ({id, name, email}) => ({
  type: '@auth/LOGIN',
  payload: { id, name, email },
});

export const logout = () => ({
  type: '@auth/LOGOUT',
});

export const storeLaudo = (laudo) => ({
  type: '@auth/storeLaudo',
  payload: { laudo },
});

export const clearStoredLaudo = () => ({
  type: '@auth/clearStoredLaudo',
});