import styled from "styled-components";
import { Grid, Button as RawButton } from "@material-ui/core";

export const Header = styled(Grid)`
  background-color: #257ce1;
  height: 100px;
`;

export const LottieWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(255, 255, 255, 0.9);
  h2 {
    margin-top: 400px;
    position: absolute;
    color: #2b7de1;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #999999;
  margin: 0;
  padding: 0;
  margin-top: 0px;
`;

export const Subtitle = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
  margin: 0;
  padding: 0;
`;

export const Button = styled(RawButton)`
  background-color: ${(props) =>
    props.isactive === "true" ? "#185194 !important" : "#257ce1 !important"};
  color: #fff !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  line-height: 32px !important;
  :hover {
    background-color: ${(props) =>
      props.isactive === "true" ? "#185194 !important" : "#00A4F4 !important"};
  }
`;
export const ContainerAbas = styled(Grid)`
  transform: translateY(-95%);
  padding-left: 20px;
`;
export const Tabela = styled(Grid)`
  margin-top: -30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 1px 0px rgba(0, 0, 0, 0.7);
  border-radius: 0px 0px 5px 5px;
`;
export const AbaIL = styled(Grid)`
  border-top: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  border-left: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  border-right: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  font-size: ${(props) => (props.isactive ? "82%" : "85%")};
  border-radius: ${(props) =>
    props.isactive ? "7px 7px 0px 0px" : "5px 5px 0px 0px"};
  display: flex;
  height: ${(props) => (props.isactive ? "25px" : "30px")};
  margin-top: ${(props) => (props.isactive ? "3px !important" : "none")};
  background-color: ${(props) => (props.isactive ? "#F1F1F2" : "#F8F9FB")};
  color: ${(props) => (props.isactive ? "rgba(0, 0, 0, 0.6)" : "none")};
  align-items: center;
  font-weight: 600;
  justify-content: center;
  transition: all 0.2s;
  cursor: pointer;
`;
export const AbaDT = styled(Grid)`
  border-top: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  border-left: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  border-right: ${(props) =>
    props.isactive ? "1px solid rgba(0, 0, 0, 0.7)" : "1px solid black"};
  font-size: ${(props) => (props.isactive ? "82%" : "85%")};
  border-radius: ${(props) =>
    props.isactive ? "7px 7px 0px 0px" : "5px 5px 0px 0px"};
  display: flex;
  height: ${(props) => (props.isactive ? "25px" : "30px")};
  margin-top: ${(props) => (props.isactive ? "3px !important" : "none")};
  background-color: ${(props) => (props.isactive ? "#F1F1F2" : "#F8F9FB")};
  color: ${(props) => (props.isactive ? "rgba(0, 0, 0, 0.6)" : "none")};
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px !important;
`;
export const TypeOF = styled.h2`
  margin: 0;
  font-family: "RopaSans";
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 64px;
  /* or 25% */
  letter-spacing: 0.394139px;
  color: #2060ae;
`;
export const TypeFF = styled.h2`
  margin: 0;
  margin-left: 0.5%;
  font-family: "RopaSans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 16px;
  /* or 44% */
  letter-spacing: 0.394139px;
  color: #e49803;
`;

export const Table = styled.div`
  border: 1px solid black;
  height: 95%;
  width: 900px;
  display: flex;
`;
export const ContainerGrafico = styled.div`
  width: calc(100%- 24px);
  background-color: #ffffff;
  margin-top: 10px;
  padding: 4px 12px;
  align-items: center;
  box-shadow: 2px 4px 19px 0px rgba(0, 0, 0, 0.67);
  border-radius: 5px;
`;
export const TableLeft = styled.div`
  width: 50%;
  padding-top: 15px;
  padding-left: 15px;
`;
export const TableRight = styled.div`
  width: 50%;
  padding-top: 15px;
`;
export const Typog = styled.td`
  font-size: 16px;
  line-height: 30px;
  font-family: "Roboto";
  color: ${(props) =>
    props.color === "true" ? "rgba(0,0,0, 0.8)" : "rgba(0,0,0, 0.4)"};
  padding-left: 25px;
  font-weight: 500;
  &.value {
    color: #185194;
    font-weight: bold;
    padding-left: 0;
  }
  &:first-of-type {
    margin-top: 8px;
  }
  &:last-of-type {
    margin-bottom: 8px;
  }
  &:nth-child(even) {
    background-color: #eeeeee;
  }
`;
export const BoxImage = styled.div`
  width: 47.5%;
  height: 47.5%;
  margin: 0.5%;
  display: flex;
`;
