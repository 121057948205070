import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import {Icon as RawIcon} from '@iconify/react'

export const Title = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #999999;
    margin: 0;
    padding: 0;
    margin-top: 0px;
`;

export const SubTitle = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #999999;
    margin: 0;
    padding: 0;
`;

export const NotFound = styled.h2`
    color: #999999;
    font-size: Arial;
    font-weight: 500;
`;


export const Container = styled.div`
    padding: 2%;
`;

export const CardContainer = styled(Grid)`
    /* grid-gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    margin-top: 12px !important;
    padding: 16px 2%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
`;

export const InfCard = styled.h2`
    margin: 4px;
    margin-left: 12px;
    margin-right: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 18px;
    color: #8f8f8f;
    b{
        
        color: #4f4f4f;
    }
    span{
        font-weight: 900
    }
`;

export const Button = styled.button`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background-color: ${(props)=> props.isactive === 'true' ? '#185194 !important' : '#257ce1   !important'};
    border-radius: 3px;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 40px;
    position: relative;
    margin-bottom: 14px;
    transition: all .2s ease;
    padding: 12px 24px !important; 
    :hover{
        background-color: ${(props)=> props.isactive === 'true' ? '#185194 !important' : '#00a4f4 !important'};
        color: #fff;
    }
    span{
        line-height: 24px;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        position: absolute;
    }
`;

export const Foto = styled.img`
    cursor: pointer;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    object-fit: contain;
    display: flex;
`;

export const TextoFoto = styled.h3`
    margin-top: 0px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
`;

export const ButtonReturn = styled.button`
    font-size: 16px;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    border: 1px #BDBDBD solid;
    color: #999999;
    background-color: #F8F9FB;
    cursor: pointer;
    border-radius: 4px;
    line-height: 30px;
    max-width: 150px;
    width: 100%;
`;

export const LottieWrapper = styled.div`
    position: absolute;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba(255, 255, 255, .9);
    h2{
        margin-top: 400px;
        position: absolute;
        color: #2b7de1;
        text-align: center;
    }
`;
export const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    left: 0 !important;
    background-color: rgba(0, 0, 0, .7) !important;
    position: absolute !important;
    z-index: 1000;
    `;
export const CloseIcon = styled(RawIcon)`
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
    z-index: 1000 !important;
    cursor: pointer;
`;
export const Icon = styled(RawIcon)`
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-58%);
    font-size:19px
`;

