import { Grid } from '@material-ui/core'
import React, { useState, UseEffect, useEffect } from 'react'
import { Button, Img, Logo, SignupText, SignupButton} from './style'
import Input from '../../components/Input'
import { useNavigate, Navigate } from 'react-router-dom'
import logo from '../../assets/logoo.png'
import { useForm } from "react-hook-form";
import api from '../../services/api'
import {toast} from 'react-hot-toast'
import {useDispatch} from 'react-redux'
import {login} from '../../redux/actions/auth'
import Loader from '../../components/Loader'
import isAuthenticated from '../../utils/auth'
import axios from 'axios'
import {store} from '../../redux/store'
import {useLocation} from 'react-router-dom'


const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const reduxLaudo = store.getState()?.auth?.laudo ? JSON.parse(window.atob(store.getState()?.auth?.laudo)).placa : null
  const { control, handleSubmit, setFocus, setValue } = useForm();
  const [isPassVisible, setPassVisible] = useState(false);
  const [isAuth, setAuth] = useState(isAuthenticated());
  const [isLoading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const handleChangeUser  = () => {
    setValue('senha', '')
    setPassVisible(false);
    setFirstAccess(false);
  }

  const formSubmit = async(e) =>{
    setInvalidUser(false)
    setIncorrectPassword(false)
    setLoading(true) // Ativar o Loader
    const { usuario, senha } = e
    try {
        const response = await api.post('/usuario/login', {usuario, senha}, {withCredentials: true})
        if (response.data?.data?.primeiro_acesso){  //Caso a api responda que é o primeiro acesso ...
          return navigate('/cadastro', {state: {usuario, id: response?.data?.data?.id}}) // ... redireciona para a definição de senha
        }
        if (response?.data?.erro) throw new Error(response?.data?.codigo)
        if (response.status === 200){
          if (!senha) {
            setPassVisible(true)
            return setLoading(false)
          }
          setLoading(false)
          toast.success(<b>Sessão iniciada.</b>, {duration: 5000})
          const {id, nome: name, email} = response.data.data
          dispatch(login({id, name, email}))
          return setAuth(isAuthenticated())
        }
      
    } catch (err) { // Erros
      if (err?.response?.status === 400) { // Usuário inválido
        setLoading(false)
        setInvalidUser(true)
        return setFocus('usuario')
      }
      if (err?.message == 0) { // Senha incorreta
        setLoading(false)
        setIncorrectPassword(true)
        setValue('senha', '')
        return setFocus('senha')  
      }
      setLoading(false)
      alert( err?.data?.mensagem || err?.mensagem || err?.response?.data?.mensagem || err?.message || err ) // Padrão / Desconhecido
    }
    
  }

  useEffect(()=>{
    if (reduxLaudo) navigate('/comprar?placa='+reduxLaudo)
    const fromCad = location?.state?.fromCad
    const email = location?.state?.email
    if (fromCad && email){
      setValue('usuario', email)
      return formSubmit({usuario: email})
    }
    
  },[])
  return  isAuth
  ? <Navigate to="/" />
  : (
  <form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
    <Loader visible={isLoading} />
    <Grid container style={{ width: '100vw', overflow: "hidden", 
     }} justifyContent='center' alignItems='center'>

        <Grid item xs={12} md={12} lg={6} style={{background: 'white', zIndex: 10, height: '100vh'}}>
          <Grid container style={{flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems:'center'}} spacing={0} p={2}>
              <Grid item>
                <Logo src={logo} />
              </Grid>
              <Grid item>
                <Input 
                  label="Usuário" 
                  name="usuario" 
                  type="text"
                  disabled={isPassVisible }
                  onDisableAdornment={{
                    tooltip: 'Trocar usuário',
                    icon:"ci:edit",
                    color:'#808080',
                    width: 22,
                    onClick: handleChangeUser
                  }}
                  rules = {{
                    required: 'Digite o usuário'
                  }}
                  customError={invalidUser ? 'Usuário inválido' : ''}
                  customcolor="#999" 
                  width={300} 
                  control={control}
                />
              </Grid>
              { isPassVisible &&
                <Grid item>
                  <Input label="Senha" 
                    name="senha" 
                    type="password"
                    customcolor="#999"
                    rules = {{
                      required: 'Insira sua senha'
                    }}
                    
                    customError={incorrectPassword ? 'Senha incorreta' : ''}
                    width={300} 
                    control={control}
                  />
                </Grid>
              }
              <Grid item>
                <Button type="submit" style={{marginBottom: 24}}>ENTRAR</Button>
              </Grid>
              <Grid item>
                <SignupText>Não possui cadastro?</SignupText>
                <SignupButton type="button" onClick={()=>navigate('/register')}>Crie uma conta</SignupButton>
              </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} lg={6} >
        </Grid>
            <Img>
              <h1>COMPRA É CERTA!</h1>
              <h1><p>Aqui sua</p> </h1>
            </Img>
    </Grid>
    
  </form>
)}

export default Login
