import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo.jpg";
import { Main, Greeting, NavigationList, NavLink, Button } from "./styles";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from '../../../redux/actions/sidebar'
import { store } from "../../../redux/store";
import { storeLaudo, clearStoredLaudo } from '../../../redux/actions/auth'
import axios from 'axios';

const NavItem = ({
  to = "/",
  Icon,
  children,
  logout = false,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  return(
    <NavLink
      onClick={() => { 
        dispatch(toggleSidebar()) }
      }
      to={logout ? "#" : to}
      disabled={disabled}
    >
      <Button startIcon={Icon}>
        <span style={{ marginLeft: 0 }}>{children}</span>
      </Button>
    </NavLink>
  )
};

const Sidebar = ({ display = true }) => {
  const dispatch = useDispatch();
  const storeName = store.getState().auth.name;
  const isOpen = useSelector((state)=>(state.sidebar))
  const [name, setName] = useState(storeName?.split(" ")[0] || "Visitante");
  const hasLaudo = useSelector((state)=>(!!state.auth.laudo))
  useEffect(() => {
    setName(storeName?.split(" ")[0] || 'Visitante');
  }, [storeName]);
  
  return (
    <Main display={display ? "true" : "false"} isopen={isOpen ? "true" : "false"}>
      <img src={Logo} />
      <Icon 
        icon="fluent:chevron-double-left-16-filled"
        color="white"
        width={32}
        className="burger-icon"
        onClick={()=>{dispatch(toggleSidebar())}}
      />
      <Greeting isopen={isOpen ? "true" : "false"}>
        Olá, <b>{name}</b>.
      </Greeting>
      <NavigationList>
        {
         hasLaudo && (
            <NavItem to="/" Icon={<Icon icon="ion:cart-outline" />}>
              Comprar Laudo
            </NavItem>
          )
        }
        <NavItem to="/meus-laudos" Icon={<Icon icon="ep:document-copy" />}>
          Meus Laudos
        </NavItem>
        <NavItem
          to="/meu-cadastro"
          Icon={<Icon icon="carbon:user-profile" />}
          disabled
        >
          Meu Cadastro
        </NavItem>
        <NavItem to="/logout" Icon={<Icon icon="grommet-icons:logout" />}>
          Sair
        </NavItem>
      </NavigationList>
    </Main>
  );
};

export default Sidebar;
