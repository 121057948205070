import styled from 'styled-components'

export const LoaderContainer = styled.div`
    position: absolute;
    z-index: 1000;
    visibility:${(props) => props.visible === 'true' ? 'visible' : 'hidden' };
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .7);
        svg{
            color: #257ce1;
        }
`;