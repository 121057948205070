import * as React from "react"

const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    className="ccard"
  >
    <path
      fill="#FFC107"
      d="M116.34 101.95H11.67c-4.2 0-7.63-3.43-7.63-7.63V33.68c0-4.2 3.43-7.63 7.63-7.63h104.67c4.2 0 7.63 3.43 7.63 7.63v60.64c0 4.2-3.43 7.63-7.63 7.63z"
    />
    <path fill="#424242" d="M4.03 38.88h119.95v16.07H4.03z" />
    <path
      fill="#FFF"
      d="M114.2 74.14H13.87c-.98 0-1.79-.8-1.79-1.79v-8.41c0-.98.8-1.79 1.79-1.79H114.2c.98 0 1.79.8 1.79 1.79v8.41c-.01.98-.81 1.79-1.79 1.79z"
    />
    <path
      fill="none"
      stroke="#424242"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M23.98 70.49c.56-1.08.71-2.34 1.21-3.45.5-1.11 1.59-2.14 2.79-1.95 1.11.18 1.8 1.29 2.21 2.33.57 1.45.88 3 .92 4.56.01.32-.01.67-.22.92-.37.42-1.13.21-1.42-.27-.29-.48-.22-1.09-.09-1.64.62-2.55 2.62-4.72 5.11-5.54.26-.09.53-.16.8-.11.58.11.9.71 1.16 1.23.61 1.19 1.35 2.32 2.2 3.35.34.42.73.83 1.25.99 1.71.5 2.7-2.02 4.35-2.69 1.98-.8 3.91 1.29 6.01 1.68 3.07.57 4.7-1.82 7.39-2.43.36-.08.75-.13 1.11-.03.66.19 1.07.82 1.46 1.39.91 1.34 2.21 2.66 3.83 2.67 1.03.01 1.98-.52 2.92-.97 3.33-1.59 7.26-2.25 10.74-1.03"
    />
  </svg>
)

export default SvgComponent
