const localStorageUser = localStorage.getItem('persist:root');
const auth = localStorageUser && JSON.parse(JSON.parse(localStorageUser)?.auth)
const id = auth?.name;
const name = auth?.name;
const email = auth?.email;
const laudo = auth?.laudo;

const initialState = {id: id || null, name: name || null, laudo: laudo || null, email: email || null}

export default function func(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case '@auth/LOGIN':
      return {
        ...state,
        id: btoa(payload.id),
        name: payload.name,
        email: btoa(payload.email),
      };
    case '@auth/storeLaudo':
      return {
        ...state,
        laudo: payload.laudo
      };

    case '@auth/clearStoredLaudo':
      return {
        ...state,
        laudo: null
      };

    case '@auth/LOGOUT':
      return {
        ...state,
        id: null,
        name: null,
        email: null,
      };
    default:
      return state;
  }
}
