import React from 'react'
import { LoaderContainer } from './styles'
import { CircularProgress } from '@material-ui/core'
import Lottie from 'react-lottie'
import animationData from '../../assets/lotties/loading'

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loader = ({ visible = false, style={}  }) => (
    <LoaderContainer style={style} visible={visible ? 'true' : 'false'}>
      <Lottie 
      options={animationOptions}
      height={400}
      width={400}
    />
      {/* <CircularProgress disableShrink thickness={4} size="8rem" /> */}
    </LoaderContainer>
)

export default Loader;