import React, { useState } from "react";
import {
  Main,
  Logo,
  PageTitle,
  Card,
  Button,
  Title,
  TextField as RawTextField,
  Stepper,
  QontoConnector,
  GoBackButton
} from "./styles";
import { Grid, MenuItem, Step, StepLabel } from "@material-ui/core";
import {useNavigate} from 'react-router-dom'
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import PNGLogo from "../../assets/logoo.png";
import { Icon } from '@iconify/react'
import Swal from 'sweetalert2'
import { store } from "../../redux/store";
import api from '../../services/api'

const TextField = (props) => (
  <RawTextField
    variant="outlined"
    fullWidth={true}
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

const Register = () => {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate()
  const [passMismatch, setPassMismatch] = useState(false)
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ["Dados Pessoais", "Endereço e Senha"];
  
  const noDefaultOpt =  (val) => { if (val === 'default') return 'Selecione uma opção';}
  
  const handleFirstStep = (e) => {
      setCurrentStep(1);
  };

  const handleGoBack = () => {
    Swal.fire({
        title: 'Tem certeza?',
        icon: 'warning',
        iconColor: '#9c0100',
        text: 'Todos os campos do formulário serão limpos se você sair.',
        showCancelButton: true,
        confirmButtonColor: '#9c0100' ,
        cancelButtonColor: '#257ce1',
        confirmButtonText: 'Sair',
        cancelButtonText: 'Continuar cadastro',
        customClass: {
          cancelButton: 'register-cancel'
        }
      }).then((result) => {
        if (result.isConfirmed) {
         navigate('/login')
        }
      })
  }

  const onFormSubmit = async (e) => {
    if (currentStep === 0) return handleFirstStep(e);
    const {
      nome,
      cpfcnpj,
      email,
      celular,
      data_nascimento,
      rg,
      estadocivil,
      endcep,
      endlogradouro,
      endnumero,
      enduf,
      endcidade,
      endbairro,
      endcomplemento,
      senha,
      senhaConfirmation
    } = e
    if (senha !== senhaConfirmation) {
      return setPassMismatch(true)
    }

    const placa = store.getState()?.auth?.laudo ? JSON.parse(window.atob(store.getState()?.auth?.laudo)).placa : null
    try{
      const response = await api.post(
      '/usuario/novo',
       {
         placa: placa? window.atob(placa) : "",
        nome,
        cpfcnpj: cpfcnpj.replace(/[^0-9]+/g, ''),
        email,
        patio: 1,
        celular,
        data_nascimento,
        rg: rg.replace(/[^0-9\w]+/g, ''),
        estadocivil,
        endcep: endcep.replace(/[^0-9]+/g, ''),
        endlogradouro,
        endnumero: endnumero.replace(/[^0-9]+/g, ''),
        enduf,
        endcidade,
        endbairro,
        endcomplemento,
        senha
       }
      )
      const link = response.data.data.redirect_to.split('.com.br')[1]
      if (link && placa){
      navigate(link)
      } else navigate(`/login`, {state:{fromCad: true, email}})
    }catch(err){
      Swal.fire({icon: 'error', title: 'Erro', text: err?.data?.mensagem || err?.mensagem || err?.response?.data?.mensagem || err?.message || err})
    }
  };

  return (
    <Main container justifyContent="center" alignItems="flex-start">
        
      <Grid item xs={12} md={12}>
        <Logo>
            <GoBackButton onClick={handleGoBack}>
                <Icon icon="bx:bx-arrow-back" />
                Voltar
            </GoBackButton>
          <img src={PNGLogo} />
        </Logo>
      </Grid>
      <Grid item xs={12}>
        <PageTitle>
          Preencha seus dados para cadastrar-se no <b>Compra Certa Alesca</b>
        </PageTitle>
      </Grid>
      <Grid item xs={11} md={10} lg={7}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Card container spacing={2}>
            <Grid item xs={12}>
              <Stepper
                activeStep={currentStep}
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map((label, index) => {
                  const isStepDone = currentStep > index;
                  return (
                    <Step key={label}>
                      <StepLabel
                        onClick={() => {
                          isStepDone && setCurrentStep(index);
                        }}
                        style={{ cursor: isStepDone ? "pointer" : "default" }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
            {currentStep === 0 && (
              <>
                <Grid item xs={12}>
                  <Title>Dados Cadastrais</Title>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 2, message: "Mínimo de 2 caractéres"}
                        }}
                        control={control}
                        fullWidth
                        label="Nome Completo"
                        name="nome"
                        isRequired
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 11, message: "Preencha corretamente"}
                        }}
                        control={control}
                        fullWidth
                        label="CPF"
                        name="cpfcnpj"
                        isRequired
                        maskOptions={{mask:'999.999.999-99'}}
                        inputProps={{
                          maxLength: 11
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 9, message: "Preencha corretamente"}
                        }}
                        control={control}
                        fullWidth
                        label="RG"
                        name="rg"
                        maskOptions={{mask:'**.***.***-***'}}
                        inputProps = {{
                          maxLength: 9
                        }}
                        isRequired
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Data de Nascimento"
                        name="data_nascimento"
                        type="date"
                        isRequired
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Input
                        rules={{
                            validate : noDefaultOpt,
                        }}
                        control={control}
                        fullWidth
                        defaultValue={false || "default"}

                        label="Estado Civil"
                        name="estadocivil"
                        select
                        isRequired
                      >
                        <MenuItem
                          value="default"
                          disabled
                          style={{ display: "none !important" }}
                        >
                          Selecione
                        </MenuItem>
                        <MenuItem value="solteiro">Solteiro</MenuItem>
                        <MenuItem value="casado">Casado</MenuItem>
                        <MenuItem value="divorciado">Divorciado</MenuItem>
                        <MenuItem value="separado">Separado</MenuItem>
                        <MenuItem value="viuvo">Viúvo</MenuItem>
                      </Input>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Title style={{ marginTop: 30 }}>Contato</Title>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "30px" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={5}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        isRequired
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 9, message: "Preencha corretamente"}
                        }}
                        control={control}
                        fullWidth
                        label="Celular"
                        name="celular"
                        maskOptions={{mask: '(99) 99999-9999'}}
                        type="tel"
                        inputProps={{
                          maxLength: 11
                        }}
                        isRequired
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {currentStep === 1 && (
              <>
                <Grid item xs={12}>
                  <Title>Endereço</Title>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 8, message: "Preencha corretamente"}
                        }}
                        control={control}
                        fullWidth
                        inputProps={{
                          maxLength: 8
                        }}
                        maskOptions={{mask: '99999-999'}}
                        label="CEP"
                        name="endcep"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Logradouro"
                        name="endlogradouro"
                        inputProps={{
                          maxLength: 40 
                        }}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Número"
                        name="endnumero"
                        maskOptions={{mask:'99999999'}}
                        type="tel"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 2, message: "Preencha corretamente"}
                        }}
                        control={control} 
                        fullWidth
                        label="Estado"
                        name="enduf"
                        type="text"
                        maskOptions={{mask:'aa'}}
                        InputProps={{
                          maxLength: 2
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Cidade"
                        name="city"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Bairro"
                        name="endbairro"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                        }}
                        control={control}
                        fullWidth
                        label="Complemento"
                        name="endcomplemento"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Title>Defina sua Senha</Title>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 8, message: "Mínimo de 8 caractéres"}
                        }}
                        control={control}
                        fullWidth
                        label="Senha"
                        name="senha"
                        type="password"
                        customError={passMismatch && 'As senhas não conferem'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        rules={{
                          required: "Este campo é obrigatório.",
                          minLength: {value: 8, message: "Mínimo de 8 caractéres"}
                        }}
                        control={control}
                        fullWidth
                        label="Confirmar Senha"
                        name="senhaConfirmation"
                        type="password"
                        customError={passMismatch && 'As senhas não conferem'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
          <Button type="submit">
            <span>{currentStep === 1 ? "Concluir Cadastro" : "Continuar"}</span>
          </Button>
        </form>
      </Grid>
    </Main>
  );
};
export default Register;
