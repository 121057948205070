import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import Confirmed from './Confirmed'
import Payment from './Payment'
import Loader from '../../components/Loader'
import { Container, Title, Legend, Card, TextField as RawTextField, MenuItem, PayButton } from './styles'

const Pagamento = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] =  useState(true)
    const isPaymentConfirmed = location?.state?.confirmed === true
    return (
        <>
            {loading && <Loader visible={loading} />}
            <Container>
                <Title>Pagamento</Title>
                <Legend>Informe abaixo os dados para pagamento do laudo.</Legend>
                {
                    isPaymentConfirmed
                    ? <Confirmed />
                    : <Payment loading={loading} setLoading={setLoading} />
                }
            </Container>
        </>
    )
}

export default Pagamento