import styled from 'styled-components';
import { Grid, Button as RawButton } from '@material-ui/core'
import { NavLink as RawNavLink } from 'react-router-dom'

export const Main = styled.div`
    display: ${(props)=> props.display==="true" ? 'block' : 'none'};
    width: ${(props)=> props.isopen==="true" ? "240px" : "0px"};
    transition: width .2s ease;
    height: 100%;
    background-color: #2b7de1;
    align-items: flex-start;
    overflow: hidden;
    z-index: 10001;
    position: relative;
    @media (max-width: 767px){
        width: ${(props)=> props.isopen==="true" ? "100vw" : "0px"};
        position: absolute;
    }
    @media (min-width: 768px){
        width: 240px;
    }
    img { 
        max-width: 70%;
        display: flex;
        margin: 40px 0;
        margin-left: 13%;
        display: flex;
        @media (max-width: 767px){
            margin: 40px auto;
            display: none;
        }
    }
    .burger-icon{
        position: absolute;
        top: 18px;
        right: 12px;
        cursor: pointer;
        display:  ${(props)=> props.isopen==="true" ? "" : "none"};
        @media (min-width: 768px){
            display: none;
        }
    }

    a.active button{
        background-color: #66a3ea !important;
    };



`;



export const Greeting = styled.p`
    font-family: 'Montserrat';
    color: white;
    font-size: ${(props)=> props.isopen === "true" ? "1rem" : "0"};
    transition: font-size .2s ease;
    text-align: center;
    margin: 30px 0;
    height: 40px;
    @media (min-width: 768px){
        margin: 60px 0 30px 0;
        font-size: 1rem;
    }
    div {
    
    }
    `;

export const NavigationList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size:20px;
    .MuiButtonBase-root button > span{
        text-align: center;
        width: auto !important;
    }
    @media (max-width: 767px){
        margin: auto;
    }
    `;

export const Button = styled(RawButton)`
    font-size: 14px !important;
    color: #fff !important;
    font-weight: normal !important;
    text-transform: none !important;
    margin: 0 !important;;
    height: 100%;
    width: 100%;
    justify-content: flex-start !important;
    padding: 4px 8px !important;
    
    :hover{
        background-color: #66a3ea !important;
    }
    @media (max-width: 768px){
        justify-content: center !important;
    }
`;
export const NavLink = styled(RawNavLink)`
    width: 80%;
    display: flex;
    position: relative;
    text-decoration: none;
    align-items: center;
    height: 60px;
    :not(:last-child):after{
        width: 95%;
        content: '';
        left: 2.5%;
        bottom: 0px;
        height: 1px;
        background: #66a3ea ;
        position: absolute;
    }
    :not(:first-child){
        margin-top: -1px;
    }
    
    `;