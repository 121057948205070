import React, { useState, useEffect } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import Loader from '../../components/Loader'
import Input from '../../components/Input'
import { useForm } from 'react-hook-form'
import { Icon } from '@iconify/react'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import api from '../../services/api'
import { Container, Title, Legend, Card, SectionTitle, Label, Value, Button, SubmitBtn } from './styles'
import moment from 'moment'

const EditButtons = ({isEditting, toggleEditting}) => (
    isEditting
    ? <>
        <Button save="true" endIcon={<Icon icon="iconoir:save-floppy-disk" width={15}/>}>
            Salvar
        </Button>
        <Button className="cancel" endIcon={ <Icon icon="iconoir:cancel" width={15} /> }  onClick={toggleEditting}>
            Cancelar
        </Button>
    </>
    : <Button
        endIcon={ <Icon icon="bi:pencil-square" width={15}/> }  onClick={toggleEditting}>
          Editar
      </Button>
)

const MeusLaudos = () => {
    const [isLoading, setLoading] = useState(true)
    const userID = useSelector(store=> (store.auth.id) )
    const [userData, setUserData] = useState({})
    const [isEditting, setEditting] = useState({contact: false, address: false})
    const {control, handleSubmit} = useForm()

    const onFormSubmit = (e) => {
        setLoading(true)
        // let dataDiff = {}
        // Object.keys(e).forEach((key)=>{
        //     if (userData[key] !== e[key] && e[key]) dataDiff[key] = e[key]
        // })
        const {
            id,
            patio,
            nome,
            cpfcnpj,
            email,
            celular,
            datanascimento,
            rg,
            estadocivil,
            endcep,
            endlogradouro,
            endnumero,
            enduf,
            endcidade,
            endbairro,
            endcomplemento
        } = e
        const formData = {
            id:id || userData.id,
            patio: patio || userData.patio,
            nome:nome || userData.nomerazao,
            cpfcnpj:cpfcnpj || userData.cpfcnpj,
            email:email || userData.email,
            celular:celular.replace(/[^0-9]+/g, '') || userData.celular,
            data_nascimento:moment(datanascimento).format('DD/MM/YYYY') || userData.datanascimento,
            rg:rg || userData.rg,
            estadocivil:estadocivil || userData.estadocivil,
            endcep:endcep.replace(/[^0-9]+/g, '') || userData.endcep,
            endlogradouro:endlogradouro || userData.endlogradouro,
            endnumero:endnumero.replace(/[^0-9]+/g, '') || userData.endnumero,
            enduf:enduf || userData.enduf,
            endcidade:endcidade || userData.endcidade,
            endbairro:endbairro || userData.endbairro,
            endcomplemento:endcomplemento || userData.endcomplemento,
        }
        toast.promise(
            new Promise((resolve, reject) => {
                return api.post('usuario/alterar_user', formData)
                .then( resp =>{
                    const timeoutId = setTimeout(()=>{setLoading(false); clearTimeout(timeoutId)}, 1500)
                    resolve(resp.data.data);
                })
                .catch(err =>{
                    setLoading(false);
                    reject(err);
                })
            }),
             {
               loading: 'Salvando...',
               success: <b>Dados Salvos!</b>,
               error: <b>Não foi possível salvar.</b>,
             }
        );
    }

    useEffect(()=>{


        const fetchUserData = async()=>{
            const decodedID = window.atob(userID)
            const resp = await api.get('/usuario/detalhes?id='+decodedID)
            setUserData(resp.data.data)
        }
        fetchUserData()
        .then(()=> {
            const timeoutId = setTimeout(()=>{setLoading(false); clearTimeout(timeoutId)}, 1500)
            //input masks

            //end input masks
        })
        
    },[])
    return (
        <>
            {isLoading && <Loader visible={isLoading} style={{height: 'calc(100% - 60px)'}}/>}
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Container>
                    <Title>Meu Cadastro</Title>
                    <Legend>Confira e altere seus dados</Legend>
                    {!isLoading && <Card container alignItems="flex-start">
                        <Grid item xs={12}>
                            <SectionTitle first="true">Dados Cadastrais</SectionTitle>
                        </Grid>

                        <Label item xs={12} md={3} lg={4}>
                            Nome:
                        </Label>
                        <Value item xs={12} md={9} lg={8}>
                            {userData?.nomerazao}
                        </Value>
                        <Label item xs={12} md={3} lg={4}>
                            CPF:
                        </Label>
                        <Value item xs={12} md={9} lg={8}>
                            {userData?.cpfcnpj?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4') || '-'}
                        </Value>
                        <Label item xs={12} md={3} lg={4}>
                            RG
                        </Label>
                        <Value item xs={12} md={9} lg={8}>
                            {userData?.rg?.replace(/(^\d{1,2}).?(\d{3}).?(\d{3})-?(\d{1}|X|x$)/g, '$1.$2.$3-$4') || '-'}
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Estado Civil:
                        </Label>
                        <Value item xs={12} md={9} lg={8}  edit="true">
                            <Grid item xs={12} md={6} lg={7}>
                                <Input name="estadocivil" select control={control} defaultValue={userData?.estadocivil || "solteiro"} fullWidth>
                                    <MenuItem value="solteiro">Solteiro</MenuItem>
                                    <MenuItem value="casado">Casado</MenuItem>
                                    <MenuItem value="separado">Separado</MenuItem>
                                    <MenuItem value="divorciado">Divorciado</MenuItem>
                                    <MenuItem value="viuvo</">Viuvo</MenuItem>
                                </Input>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4}>
                            Data de Nascimento:
                        </Label>
                        <Value item xs={12} md={9} lg={8}>
                            <Grid item xs={12} md={6} lg={7}>
                                <Input type="date" name="datanascimento" control={control} defaultValue={userData?.datanascimento} fullWidth/>
                            </Grid>
                        </Value>


                        <Grid item xs={12}>
                            <SectionTitle>Contato</SectionTitle>
                        </Grid>

                        <Label item xs={12} md={3} lg={4} edit="true">
                            Email:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={12}>
                                <Input name="email" type="email" control={control} defaultValue={ userData?.email } fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Celular:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={12}>
                                <Input
                                    name="celular"
                                    control={control}
                                    maskOptions={{mask: '(99) 99999-9999'}}
                                    inputProps={{id: 'phone-number'}}
                                    defaultValue={ userData?.celular }
                                    fullWidth
                                />
                            </Grid>
                        </Value>

                        <Grid item xs={12}>
                            <SectionTitle>Endereço</SectionTitle>
                        </Grid>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            CEP:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={6}>
                                <Input name="endcep" control={control} defaultValue={ userData?.endcep} maskOptions={{mask:'99999-999'}} fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Estado:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={2} lg={2}>
                                <Input name="enduf" control={control} defaultValue={ userData?.enduf} maskOptions={{mask: 'aa'}} fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Cidade:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={12}>
                                <Input name="endcidade" control={control} defaultValue={ userData?.endcidade} fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Logradouro:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={12}>
                                <Input name="endlogradouro" control={control} defaultValue={ userData?.endlogradouro} fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Número:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={2} lg={4}>
                                <Input name="endnumero" maskOptions={{mask:'9999999999'}} control={ control } defaultValue={ userData?.endnumero } fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Bairro:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                            <Grid item xs={12} md={6} lg={12}>
                                <Input name="endbairro" control={control} defaultValue={ userData?.endbairro} fullWidth/>
                            </Grid>
                        </Value>
                        <Label item xs={12} md={3} lg={4} edit="true">
                            Complemento:
                        </Label>
                        <Value item xs={12} md={9} lg={8} edit="true">
                                <Grid item xs={12} md={6} lg={12}>
                                        <Input name="endcomplemento" control={control} defaultValue={ userData?.endcomplemento} fullWidth/>
                                </Grid>
                        </Value>
                        <SubmitBtn type="submit">
                            SALVAR
                        </SubmitBtn>
                    </Card>}
                </Container>
            </form>
        </>
    )
}


export default MeusLaudos;