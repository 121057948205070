import styled from 'styled-components'
import img from '../../assets/4redes_mao.png'

export const Img = styled.div`
    height: 100%;
    width: 50%;
    background-image: url(${img});
    display: block;
    position: absolute;
    right: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    align-content: flex-end;

    h1{
    bottom: 10px;
    display: block;
    position: absolute;
    color: white;
    left: 0;
    margin-left: 40px;
    font-family: Arial;
    }
`;

export const Logo = styled.img`
    width: 40%;
    display: block;
    margin: 12px auto;
`;

export const Button = styled.button`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background: #257CE1;
    border-radius: 5px;
    border:none;
    cursor: pointer;
    width: 300px;
    height: 45px;
    :hover{
    background: deepskyblue;
    }
`;

export const SignupText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    margin:0;
    padding: 0;
`;

export const SignupButton = styled.button`
    font-family: Roboto;
    font-size: 14px;
    background: none;
    border: none;
    color: #6277f3;
    width: 100%;
    :hover{
        text-decoration: underline;
        cursor: pointer;
    }

`;