import React from 'react'
import { Container, IconButton } from './styles'
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../../assets/logo-inverse.png";
import { toggleSidebar } from '../../../redux/actions/sidebar';

const Header = ({display=true}) => {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(store => (store.sidebar))

    return(
    <Container display={display ? 'true' : 'false'} isopen = { isOpen ? "true" : "false"}>
      <IconButton type="button" onClick={()=>{dispatch(toggleSidebar())}}>
        <Icon 
          icon="ci:hamburger"
          width={32}
          color={'white'}
          className="burger-icon"
        />
      </IconButton>
      <img src={Logo} />
    </Container>
)};


export default Header;