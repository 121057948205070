import styled from 'styled-components'

export const Logo = styled.img`
  width: 40%;
  display: block;
  margin: 12px auto;
`;

export const Texto1 = styled.h1`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
text-align: center;
color: #333333;
margin: 0;
padding: 0;
`;

export const Texto2 = styled.h1`
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: center;
margin: 0;
padding: 0;
`;

export const Button = styled.button`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background: #257CE1;
    border-radius: 5px;
    border:none;
    cursor: pointer;
    width: 300px;
    height: 45px;
    :hover{
    background: deepskyblue;
    }
`;