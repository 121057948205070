import styled from 'styled-components';

export const Main = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-X: hidden;
    position: relative;
`;

export const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    @media (max-width: 767px){
        display: ${(props)=> props.isopen === "true" ? "none" : ""};
    }
    div#content{
        margin-top: 0;
        height: 100%;
        background-color: #f8f9fb;
        overflow-Y: auto;
        @media (max-width: 767px){
            margin-top: ${(props) => props.display === "true" ? '60px' : 0 };
        }
    }
`;
