import logo from './logo.svg';
import './App.css';
import Routes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { Toaster } from 'react-hot-toast';
import HttpsRedirect from 'react-https-redirect';
import "react-image-gallery/styles/css/image-gallery.css";

const App = () => {
  if (window.location.protocol == 'http:' && process.env.NODE_ENV !== 'development') {
    console.log("you are accessing us via "
        +  "an insecure protocol (HTTP). "
        + "Redirecting you to HTTPS.");
         
    window.location.href = window.location.href
    .replace('http:', 'https:');
}
else if(window.location.protocol == "https:") {
        console.log("you are accessing us via"
            + " our secure HTTPS protocol.");
    }
  return (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Toaster position="top-center" />
            <Routes />
        </PersistGate>
    </Provider>
  );
}

export default App;
