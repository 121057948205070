import React from 'react';
import Layout from '../components/Layout'
import Pagamento from '../pages/Pagamento'
import MeusLaudos from '../pages/MeusLaudos'
import MeuCadastro from '../pages/MeuCadastro'
import Laudo from '../pages/Laudo'
import {
  Routes as Switch,
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Register from '../pages/Register';
import Cadastro from '../pages/Cadastro';
import FotosLaudo from '../pages/MeusLaudos/fotos';
import { Navigate } from 'react-router-dom'
import ProtectedRoute, {ProtectedRoutesList} from './ProtectedRoute'
import NovaCompra from '../pages/NovaCompra';
import { useSelector } from 'react-redux';


const Routes = () => {
    const laudo = useSelector((props)=>(props.auth.laudo))
    return(
        <Router>
            <Layout>
                <Switch>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/cadastro" element={<Cadastro/>} />
                    <Route path="/comprar" element={<NovaCompra/>} />
                    {/* Rotas Protegidas */}
                    <Route  path="/" element={<ProtectedRoute />}>
                        <Route path="*" element={<Navigate to='/meus-laudos' replace/>} />
                        <Route path="/meus-laudos" element={ <MeusLaudos /> } />
                        <Route path="/meu-cadastro" element={ <MeuCadastro /> } />
                        <Route path="/meus-laudos/laudo" element={<Laudo />} />
                        <Route path="/meus-laudos/fotos" element={ <FotosLaudo/> }/>
                        <Route path="/logout" element={<Logout />} />

                        <Route path="/" element={ laudo ? <Pagamento /> : <Navigate to='/meus-laudos' replace/>} />
                    </Route>
                </Switch>
            </Layout>
        </Router>
    )
};

export default Routes;
