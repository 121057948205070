import {
  ButtonReturn,
  CardContainer,
  Container,
  Foto,
  NotFound,
  SubTitle,
  TextoFoto,
  Title,
  ModalContainer,
  CloseIcon,
  Button,
} from "./styles";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import getImgURL from "../../utils/getImgUrl";
import semFoto from "../../assets/imgs/sem-foto.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Modal,
  Fade,
  ButtonGroup,
  Button as RawButton,
} from "@material-ui/core";
import api from "../../services/api";
import ImageGallery from "react-image-gallery";
import RawImageMagnify from "react-image-magnify";

const Fotos = ({ caminho, legenda, onClick }) => (
  <Grid item xs={12} md={4} lg={3} style={{ padding: "1%" }}>
    <a onClick={onClick}>
      <Foto src={caminho} />
    </a>
    <TextoFoto>{legenda}</TextoFoto>
  </Grid>
);

const FotosLaudo = () => {
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const veiculo = location?.state?.veiculo || {};
  const [isOpen, setOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galeriaState, setGaleriaState] = useState(false);
  const [galeriaLoc, setGaleriaLoc] = useState(0);

  const handleLoc = (e) => {
    const val = e.currentTarget.value;
    if (val === galeriaLoc) return setGaleriaLoc(0);
    setGaleriaLoc(e.currentTarget.value);
  };

  const handleGaleria = () => {
    setGaleriaState(!galeriaState);
  };

  const fetchImages = async () => {
    try {
      const images = await api.post("/laudo/fotos", { vistoria: veiculo.id });
      const parsedImages = images.data.data.map((each) => {
        const { id, pasta, legenda: filename, extensao, locfoto } = each;
        const URL = getImgURL({
          id,
          pasta,
          filename,
          extensao,
          formaRedimenciona: "wh",
          medidas: "500+500",
          qualidade: "",
        });
        return { legenda: filename, caminho: URL, locfoto };
      });
      setImages(parsedImages);
      setGalleryImages(parsedImages);

      return images;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      const timeoutId = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeoutId);
      }, 1500);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!veiculo?.id) navigate("/meus-laudos", { replace: true });
    else {
      fetchImages();
      const timeoutId = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeoutId);
      }, 1500);
    }
  }, []);
  useEffect(() => {
    //Filtro Apontamentos
    let tempImgs = !galeriaState
      ? images
      : images?.filter((img) =>
          img?.legenda?.toLowerCase()?.includes("avaria")
        );
    //Filtro Localização
    setGalleryImages(
      galeriaLoc != 0
        ? tempImgs.filter((img) => img.locfoto == galeriaLoc)
        : tempImgs
    );
  }, [galeriaState, galeriaLoc]);
  return (
    <>
      {isLoading && <Loader visible={isLoading} style={{ height: "100vh" }} />}
      {isOpen && (
        <ModalContainer>
          <CloseIcon
            icon="bytesize:close"
            width={60}
            color="white"
            onClick={() => {
              setOpen(false);
              setStartIndex(0);
            }}
          />
          {isOpen && (
            <ImageGallery
              startIndex={startIndex}
              renderItem={(props) => (
                <RawImageMagnify
                  {...{
                    smallImage: {
                      isFluidWidth: true,
                      src: props.caminho,
                      width: 515,
                      height: 290,
                    },
                    largeImage: {
                      src: props.caminho,
                      width: 2575,
                      height: 1450,
                    },
                    enlargedImagePosition: "over",
                    enlargedImageContainerDimensions: {
                      width: "200%",
                      height: "100%",
                    },
                    style: {
                      marginTop: "-2%",
                      zIndex: "10",
                    },
                    enlargedImageContainerClassName: "enlargedContainer",
                    className: "rootClass",
                  }}
                />
              )}
              items={galleryImages.map((img) => ({
                ...img,
                description: img.legenda,
                original: img.caminho,
                thumbnail: img.caminho,
              }))}
            />
          )}
        </ModalContainer>
      )}
      <Container>
        <Title>
          Fotos - {veiculo.marca} {veiculo.modelo}
        </Title>
        <SubTitle style={{ paddingBottom: "0px !important" }}>
          Listamos as fotos do laudo, clique para ampliar.
        </SubTitle>

        <Grid
          container
          justifyContent="space-between"
          style={{ paddingTop: 8, paddingBottom: 0 }}
        >
          <Grid item xs={2}>
            <ButtonReturn
              onClick={() =>
                navigate("/meus-laudos/laudo", { state: { veiculo } })
              }
            >
              {" "}
              {"< Voltar"}{" "}
            </ButtonReturn>
          </Grid>
        </Grid>

        <CardContainer container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <RawButton
                  onClick={handleGaleria}
                  fullWidth
                  style={{ backgroundColor: "#2b7de1", color: "#fff" }}
                >
                  {!galeriaState ? "Ver Apontamentos" : "Ver Todas"}
                </RawButton>
              </Grid>
              <Grid item xs={3}>
                <ButtonGroup
                  variant="contained"
                  fullWidth
                  style={{
                    width: "100% !important",
                    height: "100% !important",
                    boxShadow: "none",
                  }}
                >
                  <Button
                    value="2"
                    isactive={2 == galeriaLoc ? "true" : ""}
                    fullWidth
                    onClick={handleLoc}
                  >
                    Interno
                  </Button>
                  <Button
                    value="1"
                    isactive={1 == galeriaLoc ? "true" : ""}
                    fullWidth
                    onClick={handleLoc}
                  >
                    Externo
                  </Button>
                  <Button
                    value="3"
                    isactive={3 == galeriaLoc ? "true" : ""}
                    fullWidth
                    onClick={handleLoc}
                  >
                    Estrutura
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>

          {galleryImages.length > 0 ? (
            galleryImages.map((img, index) => (
              <Fotos
                key={img.caminho}
                onClick={() => {
                  setStartIndex(index);
                  setOpen(true);
                }}
                legenda={img.legenda}
                caminho={img.caminho}
              />
            ))
          ) : (
            <NotFound>Sem fotos disponíveis no momento</NotFound>
          )}
        </CardContainer>
      </Container>
      <Grid container style={{ padding: "0 1.6%", marginBottom: 32 }}>
        <ButtonReturn
          onClick={() => navigate("/meus-laudos/laudo", { state: { veiculo } })}
        >
          {" "}
          {"< Voltar"}{" "}
        </ButtonReturn>
      </Grid>
    </>
  );
};

export default FotosLaudo;
