// Pegar URL da imagem
function caminhoImagem({
  id = "",
  pasta = "",
  fileName = "imagem",
  formaRedimenciona = "",
  medidas = "",
  qualidade = "",
  extensao = "",
}) {
  if (!id || !pasta || !extensao) return null;
  let pastaSplit = pasta.split("/");
  let pastaCode = `${pastaSplit[0]}-${pastaSplit[1]}${pastaSplit[2]}${pastaSplit[3]}`;
  if (qualidade) qualidade += "/";
  let file = fileName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-");
  return `https://sistema.alesca.com.br/Imagem/${id}/${pastaCode}/df/${qualidade}${file}.${extensao}`;
}
// imagem de Ex: /api/laudo/fotos

// let a = caminhoImagem({
//     id: "26822",
//     fileName: "Foto Avaria Vidro Direito Dianteiro",
//     pasta: "vistoriafoto/2021/08/27",
//     extensao: "jpg",
//     formaRedimenciona: "df",
//     medidas: "500+500",
//     qualidade: ""
// })

// Imagem de Ex: /api/laudo/listar

// let b = caminhoImagem({
//     id: "29578",
//     pasta: "vistoriafoto/2021/09/02",
//     fileName: "CELTA HATCH",
//     extensao: "jpg",
//     formaRedimenciona: "wh",
//     medidas: "1000+1000",
//     qualidade: "100"
// })
export default caminhoImagem;
