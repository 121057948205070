import styled from 'styled-components'
import { Grid, Button as RawButton } from '@material-ui/core'


export const Title = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #999999;
    margin: 0;
    padding: 0;
`;
export const Legend = styled.h3`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #999999;
    margin: 5px 0 32px 0;
    padding: 0;
`;

export const Container = styled.div`
    margin: 2%;
    width: 65%;
    transition: all .2s ease;
    padding-bottom: 40px;
    @media (max-width: 1279px){
        width: 96%;
    }
`;

export const Card = styled(Grid)`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 24px 48px;
    position: relative;
    @media (max-width: 600px) {
        padding: 20px;
    }
`;

export const SectionTitle = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    color: #E49803;
    text-transform: uppercase;
    height: auto;
    margin: 0;
    margin-bottom: 18px;
    margin-top: ${(props)=> props.first === "true" ? "0" : "16px"};
    :after{
        content: '';
        position: absolute;
        margin: 0 1.2%;
        width: 97.6%;
        margin-top: 24px;
        left: 0;
        
        height: 2px;
        background-color: #E49803;
    }
`;

export const Label = styled(Grid)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.333333px;
    font-size: 16px;
    color: rgba(79, 79, 79, 0.8);
    line-height: 50px;
    @media (max-width: 959px){
        line-height: 35px
    }
`;

export const Value = styled(Grid)`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.333333px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    margin-top: auto;
    display: flex;
    color: rgba(79, 79, 79, 0.8);
    margin-bottom:  16px !important;
    bottom: 0 !important; 
    line-height: 50px;
    @media (max-width: 959px){
        line-height: 15px
    }
`;

export const Button = styled(RawButton)`
    color: #257CE1 !important;
    position: absolute !important;
    margin-top: -38px !important;
    padding: 4px 8px !important;
    border-radius: 5px !important;
    right: 5.8%;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration-line: underline !important;

    ${(props) => props.save === "true" 
        ? `
            right: 23% !important;
            transition: all .2s ease !important;
            background-color: #257CE1 !important;
            color: #FFF !important;
            text-decoration-line: none !important;
            @media (max-width: 1340px){
                right: 24% !important;
            }
            @media (max-width: 1279px){
                right: 18% !important;
            }
            @media (max-width: 1000px){
                right: 24% !important;
            }
            @media (max-width: 780px){
                right: 28% !important;
            }
            @media (max-width: 460px){
                right: 36% !important;
            }
            @media (max-width: 350px){
                right: 9.8% !important;
                margin-top: -64px !important;
            }
        ` 

        : ''
    }
`;

export const SubmitBtn = styled.button`
    background-color: #257CE1 !important;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 4px 12px;
    width: 100%;
    min-height: 50px;
    margin-top: 24px;

`;