import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import RawTextField from '@material-ui/core/TextField'
import RawButton from '@material-ui/core/Button'
import RawStepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector'

export const Main = styled(Grid)`
    background-color: #f8f9fb;
    width: 100%;
    position: relative;
`;

export const GoBackButton = styled(RawButton)`
    position: absolute !important;
    left: 20%;
    top: 5.6%;
    svg{
        margin-right: 8px;
    }
    font-size: 22px !important;
    color: #257ce1 !important;
    padding: 0px 8px !important;
    @media (max-width: 1279px){
        left: 7%;
    }
    @media (max-width: 959px){
        left: 3%;
        top: .6%;
    }
`;
export const Logo = styled.div`
    width: 15%;
    margin: 37px auto 20px auto;
    
    img {
        width: 100%;
    }
    @media (max-width: 2560px){
            width: 20%;
    }
    @media (max-width: 1440px){
            width: 30%;
    }
    @media (max-width: 1024px){
            width: 40%;
    }
    @media (max-width: 767px){
            width: 90%;
    }

    img {
        width: 100%;
    }
`;

export const PageTitle = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9rem
    line-height: 26px;
    text-align: center;
    color: #333333;
    margin: 0;
    padding: 0;
    @media (max-width: 2560px){
            font-size: 1.4rem;
    }
    @media (max-width: 1440px){
        font-size: 1.7rem !important;
    }
    @media (max-width: 1024px){
        font-size: 1.3rem !important;
    }
    @media (max-width: 767px){
        font-size: 1rem !important;
    } 
`;

export const Card  = styled(Grid)`
    background-color: #fff;
    border: 1px solid #DACBCB;
    border-radius: 10px;
    padding: 1% 5%;
    margin-top: 36px !important;
    margin-bottom: 36px !important;
`;

export const Button = styled.button`
    width: 100%;
    background-color: #257CE1;
    border: none;
    border-radius: 5px;
    padding: 18px;
    color: #fff;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    margin: auto;
    margin-bottom: 40px;
    cursor: pointer;
    span{
        margin: auto;
    }

`;

export const Title = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #E49803;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 5px;
    :after{
        content: '';
        position: absolute;
        width: 104%;
        height: 1px;
        background-color: #E49803;
        left: -2%;
        bottom: 0px;
    }
`;

export const TextField = styled(RawTextField)`
    .MuiFormLabel-root{
        position: relative;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.333333px;
        i{
            font-style: normal !important;
            font-size: 12px !important;
        }
        color: #4F4F4F !important;
        top: -38%;
        left: 0;
        transform: translate(0px, -6px) !important;
    }
    input, .MuiSelect-root{
        height: 20px !important;
        padding: 10px !important;
        color: #5d5d5d !important;
    }
    input[value="default"]{
        color: #989898 !important;
    }
    
    /* Opções do Select de Parcelamento*/
    .Mui-focused fieldset{
        border-color: #5d5d5d !important;
    }
    legend{
        display: none;
    }
`;

export const Stepper = styled(RawStepper)`
    padding: 0 !important;
    margin-top: 3% !important;
    * {
        margin: 0 !important;
        padding: 0 !important;
    }
    .MuiSvgIcon-root{
        z-index: 1 !important;
    }
    .MuiStepLabel-labelContainer{
        margin-top: 4px !important;
    }
    .MuiStepIcon-root{
        fill: #eaeaf0 !important;
    }
    .MuiStepIcon-active{
        fill: #257ce1 !important;
    }
    .MuiStepIcon-completed{
        fill: #c9da00 !important;
    }
`;

export const QontoConnector = withStyles({
    alternativeLabel: {
        position: 'absolute',
        width: '98%',
        left: '-47%'
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#c9da00 0%,#78ac70 50%,#257ce1 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);
  