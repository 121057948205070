import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import RawMenuItem from '@material-ui/core/MenuItem'
import Input from '../../components/Input'

export const Container = styled.div`
    padding: 2%;
    @media (max-width: 767px){
        padding-bottom: 80px;
    }
    svg.ccard, svg.pix{
        height: 70px;
        display: flex;
        margin: auto;
    }

`;

export const Title = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #999999;
    margin: 0;
    padding: 0;
`;

export const Legend = styled.h3`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #999999;
    margin: 5px 0 32px 0;
    padding: 0;
`;

export const Card = styled(Grid)`
    padding: 22px;
    background: #FFFFFF;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    @media (max-width: 1279px) {
        border-bottom-left-radius: ${(props)=> (props.customid === 'payment-first' ? 0 : '')};
        border-bottom-right-radius: ${(props)=>( props.customid === 'payment-first' ? 0 : '')};
        border-top-left-radius: ${(props)=> (props.customid === 'payment-second' ? 0 : '')};
        border-top-right-radius: ${(props)=>( props.customid === 'payment-second' ? 0 : '')};
    }
    /* Título  */
    h1.card-title{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 21px;
        color: #E49803;
        position: relative;
        margin: 0;
        padding: 0;
        :after{
            left: -1.25%;
            content: '';
            width: 102.5%;
            height: 1px;
            background-color: #DFDFDF;
            position: absolute;
            bottom: -10px;
        }
    }

    /* Imagem - Detalhe da Compra */

    img{
        width: 100%;
        display: block;
        margin: 19px auto 19px auto;
    }
    
    /* Legenda Imagem - Detalhe da Compra */
    h2{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        color: #4F4F4F;
        margin: 6px 0;
    }

    /* Preço - Detalhe da Compra */
    h2.price{
        margin: 0 !important;
        padding: 0 !important;
        font-family: Roboto;
        font-style: normal;
        color: #4f4f4f !important;
        text-align: center;
        margin-top: 22px;
        font-size: 25px;
        line-height: 29px;
        span{
            font-size: 30px;
            line-height: 35px;
            font-weight: bold;
            color: #257CE1;
        }
    }
`;


export const TextField = styled(Input)`
    .MuiFormLabel-root{
        position: relative;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.333333px;
        i{
            font-style: normal !important;
            font-size: 12px !important;
        }
        color: #4F4F4F !important;
        top: -38%;
        left: 0;
        transform: translate(0px, -6px) !important;
    }
    input, .MuiSelect-root{
        height: 20px !important;
        padding: 10px !important;
        color: #000 !important;
    }
    
    /* Opções do Select de Parcelamento*/
    .Mui-focused fieldset{
        border-color: #5d5d5d !important;
    }
    legend{
        display: none;
    }
`;

export const MenuItem = styled(RawMenuItem)`
    background-color: white !important;
    :hover{
        background-color: #f0f0f0 !important;
    }
    
`;


export const PayButton = styled.button`
    width: 100%;
    background-color: #257CE1;
    z-index: 20;
    text-align: center;
    border: none;
    padding: 11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 4px;
    margin-top: 17px;
    cursor: pointer;
`;


export const ConfirmContainer = styled.div`
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;    
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 60px 20px;


    svg {
        color: #44da6a;
        width: 90px;
        height: 90px;
        margin:0;
        padding: 0;
        margin-top: -6px;
    }
`;

export const ConfirmText = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    margin: 0;
    padding: 0;
`;


export const ConfirmedButton = styled.button`
    border: 1px solid #BDBDBD;
    width: 190px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: transparent;
    margin: 20px auto;
    cursor: pointer;

`;


export const PaymentOptions = styled(Grid)`
    justify-content:center !important;
    text-align:center !important;
    color:#555555;
    font-weight: bold;
    margin-top: 10px !important;
    > div {
        cursor: pointer;
        transition: all .3s ease;
        padding: 20px;
        :hover{
            border-radius: 8px;
            background-color: rgba(0,0,0, .08)
        }
    }
    
`;

export const Section = styled.div`
    transition: all .2s ease;
    visibility: ${(props)=> props.visibility === 'true' ? 'visible' : 'hidden'};
`;

export const QRCode = styled.img`
    max-width: 400px !important;
    margin: 0;
    margin-top:-10px !important;
    padding: 0;
    z-index: 1;
`

export const PixText = styled.h3`
    color: #555555;
    text-align: center;
    span{
        font-size: 90%;
        opacity: .8;
        font-style: italic;
    }
`;