import React, {useState, useEffect}  from 'react'
import {useDispatch} from 'react-redux'
import {logout} from '../../redux/actions/auth'
import isAuthenticated from '../../utils/auth'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-hot-toast'



const Logout = () => {
    const [isAuth , setAuth] = useState(isAuthenticated());
    const dispatch = useDispatch();
    const test = isAuthenticated();
    const navigate = useNavigate();
    dispatch(logout());

    const awaitLogout = () => {
        const newAuthState = isAuthenticated()
        setAuth(newAuthState)
        const timeout = setTimeout(awaitLogout, 400)
        return clearTimeout(timeout)
    }

    useEffect(()=> {
        if (!isAuth) {
            toast.success("Sessão Encerrada", {duration: 3000})
            navigate('/login')
        }
        awaitLogout();
    } , [isAuth])
    return <p>Desconectando...</p>
}

export default Logout;