import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import {
  Container,
  Title,
  CardContainer,
  Foto,
  InfCard,
  Button,
  SubTitle,
  NotFound,
  Icon,
  LottieWrapper,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import getImgURL from "../../utils/getImgUrl";
import api, { apiFP } from "../../services/api";
import { store } from "../../redux/store";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../components/Loader";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/gerando_laudo";
import DefaultImage from "../../assets/imgs/defaulCarImage.png";

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const FunctionCard = ({ veiculo, navigate, setGenerating }) => {
  const getLaudo = async (placa) => {
    setGenerating(true);
    const res = await api.get(`/laudo/compracerta?placa=${placa}&amp&pdf=1`, {
      withCredentials: true,
      responseType: "blob",
    });
    setGenerating(false);
    const pdfUrl = window.URL.createObjectURL(res.data);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = pdfUrl;
    a.download = `${placa} Laudo Compra Certa`;
    a.click();
    window.URL.revokeObjectURL(pdfUrl);
  };
  const {
    id,
    placa,
    marca,
    modelo,
    pasta,
    arquivo,
    portas,
    anoFab,
    anoMod,
    leiloeiro,
    versao,
  } = veiculo;
  const URL =
    arquivo?.id && arquivo?.pasta && arquivo?.extensao
      ? getImgURL({
          id: arquivo.id,
          pasta: arquivo.pasta,
          extensao: arquivo.extensao,
          formaRedimenciona: "wh",
          medidas: "500+500",
          qualidade: "",
        })
      : DefaultImage;

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        border: "1px solid white",
        boxSizing: "border-box",
        boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Grid item xs={12}>
        <Foto
          src={URL}
          onClick={() => navigate("/meus-laudos/laudo", { state: { veiculo } })}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ padding: "8px  0" }}>
          <Grid item xs={12} md={12} md={6}>
            <InfCard>
              Leiloeiro: <b>{leiloeiro || "-"}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12}>
            <InfCard>
              Modelo: <b>{modelo}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12}>
            <InfCard>
              Versão: <b>{versao}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12}>
            <InfCard>
              Marca: <b>{marca}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12} md={6}>
            <InfCard>
              Placa: <b>{placa}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12} md={6}>
            <InfCard>
              <b>{portas} Portas</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12} md={6}>
            <InfCard>
              Ano Fabric.: <b>{anoFab}</b>
            </InfCard>
          </Grid>
          <Grid item xs={12} md={12} md={6}>
            <InfCard>
              Ano Modelo: <b>{anoMod}</b>
            </InfCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          style={{ padding: "4px  12px" }}
        >
          {/* <Grid item>
              <Button onClick={()=>{getLaudo(placa)}}>
                <Icon icon="line-md:document-report" color="white"/> 
                <span>PDF</span> 
              </Button>
            </Grid> */}
          <Grid item>
            <Button
              variant="text"
              onClick={() =>
                navigate("/meus-laudos/laudo", { state: { veiculo } })
              }
            >
              Detalhes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MeusLaudos = () => {
  const userId = store.getState().auth?.id;
  const [veiculos, setVeiculos] = useState([]);
  const navigate = useNavigate();
  const [isGenerating, setGenerating] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const selector = useSelector((store) => store.sidebar);

  const fetchDetails = async (veiculo) => {
    const response = await apiFP.get("/detalhes/" + veiculo?.id);
    const {
      vist_vanomodelo: anoMod,
      vist_vanofab: anoFab,
      vist_vqtdeporta: portas,
    } = response.data[0];
    return { ...veiculo, anoFab, anoMod, portas };
  };

  useEffect(() => {
    Laudos();
  }, []);

  useEffect(() => {}, [selector]);

  async function Laudos() {
    if (userId) {
      try {
        setLoading(true);
        const response = await api.post(
          "https://api.alesca.com.br/api/laudo/listar",
          { usuario: parseInt(window.atob(userId)) }
        );
        const tempVehicles = await Promise.all(
          response.data.data.map(async (each) => {
            const parsed = await fetchDetails(each);
            return parsed;
          })
        );
        setVeiculos(tempVehicles);
        const timeoutId = setTimeout(() => {
          setLoading(false);
          clearTimeout(timeoutId);
        }, 1000);
      } catch (err) {
        const timeoutId = setTimeout(() => {
          setLoading(false);
          clearTimeout(timeoutId);
        }, 1000);
        return;
      }
    }
  }

  return (
    <>
      {isLoading && <Loader visible={isLoading} />}
      {isGenerating && (
        <LottieWrapper>
          <Lottie options={animationOptions} height={400} width={400} />
          <h2>Gerando laudo. Por favor aguarde...</h2>
        </LottieWrapper>
      )}
      <Container>
        <Title>Meus Laudos</Title>
        <SubTitle>Listamos todos os laudos comprados na plataforma.</SubTitle>
        <CardContainer container>
          {veiculos.length > 0 ? (
            veiculos.map((veiculo, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                style={{ padding: "2% 1%" }}
                key={`${index}-${veiculo?.id}`}
              >
                <FunctionCard
                  veiculo={veiculo}
                  navigate={navigate}
                  setGenerating={setGenerating}
                />
              </Grid>
            ))
          ) : (
            <NotFound>Desculpe, você ainda não possui nenhum laudo</NotFound>
          )}
        </CardContainer>
      </Container>
    </>
  );
};

export default MeusLaudos;
