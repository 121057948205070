import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Button, Logo, Texto1, Texto2 } from './style';
import logo from '../../assets/logoo.png'
import Input from '../../components/Input';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom'
import api from '../../services/api'
import Loader from '../../components/Loader'
import {toast} from 'react-hot-toast'

const Cadastro = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [passMissmatch, setPassMissmatch] = useState(false)
  const user = {
    id : location?.state?.id,
    usuario : location?.state?.usuario
  }
  const {handleSubmit, control} = useForm();
  const [data, setData] = useState({});


  const resetErrorIndicators = () =>{
    setPassMissmatch(false)
  }

  const formSubmit = async (e) =>{
    setLoading(true)
    resetErrorIndicators();
    const {senha, senhaConfirmada} = e
    if (!senha || senha !== senhaConfirmada){
      setPassMissmatch(true)
      return setLoading(false)
    }
    try{
      const response = await api.post('/usuario/cadastro' , {id: user.id, senha: e.senha})
      if (response.status === 200){
        toast.success('Senha alterada!');
        return navigate('/login')
      }
    } catch (err){
      setLoading(false)
    }
  }

  useEffect(()=> {
    if (!user.id || !user.usuario){
      navigate('/login')
    }
   },[])

  return(
    <form onSubmit={handleSubmit(formSubmit)}>
      <Loader visible={isLoading} />
      <div style={{height: '100vh'}}>
        <Grid container justifyContent='center'alignItems='center' style={{flexDirection: 'column', minHeight: '100%'}}>
          <Grid item>
            <Logo src={logo} />
          </Grid>
          <Grid item style={{marginTop:14}}>
            <Texto1>Crie uma senha de acesso ao sistema </Texto1>
            <Texto2>Compra Certa Alesca</Texto2>
          </Grid>
          <Grid item style={{marginTop:28}}>
            <Input
              defaultValue={""}
              name="usuario" 
              control={control} 
              label="Email"
              customcolor="#999"
              width={300}
              type="text"
              defaultValue={user.usuario}
              disabled
            />
          </Grid>
          <Grid item style={{marginTop:10}}>
            <Input
              defaultValue={""}
              name="senha" 
              control={control} 
              label="Senha"
              customcolor="#999"
              rules={{
                required: "Escolha uma senha",
                minLength: {value: 6, message: 'Mínimo de 6 caractéres'}
              }}
              customError={passMissmatch ? ' ' : ''}
              width={300}
              type="password"
            />    
          </Grid>
          <Grid item style={{marginTop:10}}>
            <Input
              defaultValue={""}
              name="senhaConfirmada" 
              control={control} 
              label="Confirmação de Senha"
              customcolor="#999"
              rules={{
                required: "Confirme sua senha",
                minLength: {value: 6, message: 'Mínimo de 6 caractéres'}
              }}
              customError={passMissmatch ? 'As senhas não conferem' : ''}
              width={300}
              type="password"
            />  
          </Grid>
          <Grid item style={{marginTop:10}}>
            <Button type="submit">Cadastrar Senha</Button>
          </Grid>
        </Grid>
      </div>
    </form>
  )}

export default Cadastro;